import { Navigate } from 'react-router-dom';
import React from 'react';

const isAuthenticated = () => {
  const login = JSON.parse(localStorage.getItem('User'));
  return login;
};
const PrivateRoute = ({ element }) =>
  !isAuthenticated() ? <Navigate to="/login" replace /> : element;

export default PrivateRoute;
