import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  Grid,
  IconButton,
  Paper,
  Stack,
  SvgIcon,
  Tooltip,
  Typography
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore
} from 'firebase/firestore';
import { db, storage } from '../firebase';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useDispatch, useSelector } from 'react-redux';
import { hideLoader, showLoader } from '../Store/Sclies/loaderSlice';
import EditIcon from '@mui/icons-material/Edit';
import CouponEdit from '../sections/Edit/CouponEdit';
import { fetchCompanyData } from '../Store/Sclies/CamapanySlice';
import { Visibility } from '@mui/icons-material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { deleteObject, ref } from 'firebase/storage';
import { toast } from 'react-hot-toast';
const CompanyDetail = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [couponData, setCouponData] = useState([]);
  const { data } = useSelector((state) => state.company);
  const dispatch = useDispatch();

  const navigat = useNavigate();
  const { id } = useParams();
  const [itemCoupon, setItemCoupon] = useState([]);
  var logo = data.find((o) => o.company_Name === id);

  useEffect(() => {
    dispatch(showLoader());
    fetchData();
    dispatch(fetchCompanyData());
  }, []);

  const fetchData = async () => {
    try {
      let arr = [];
      const ref = collection(db, 'Coupon');
      const querySnapshot = await getDocs(ref);

      querySnapshot.forEach((doc) => {
        arr.push({ id: doc.id, data: doc.data() });
      });
      const newData = arr.map((data) => data.data);
      const finalData = newData.filter((res) => res.CompanyName === id);
      const filteredDataWithIds = arr.filter((item) =>
        finalData.some((res) => res.CompanyName === id && res === item.data)
      );
      arr = filteredDataWithIds;
      setItemCoupon(arr);
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      console.error('Error fetching data:', error);
    }
  };

  const handleCardClick = (couponId) => {
    navigate(`/company/${id}/${couponId}`);
  };
  // const AddHomeCouponFirebase = async (data, company) => {
  //   dispatch(showLoader()); //
  //   try {
  //     const documentRef = collection(db, 'HomepageCoupon');
  //     await addDoc(documentRef, {
  //       Company: company,
  //       coupon: data
  //     }).then(() => {
  //       toast.success(' Add Coupon Successfully!');
  //     });
  //     dispatch(hideLoader());
  //   } catch (error) {
  //     dispatch(hideLoader());
  //     toast.error(' Coupon not  added ');
  //     console.error('Error updating array in Firestore:', error);
  //   }
  // };

  const deleteFileFromStorage = async (downloadURL) => {
    const storageRef = ref(storage, downloadURL);

    try {
      await deleteObject(storageRef);
    } catch (error) {
      console.error('Error deleting file: ', error);
    }
  };

  const deleteSubcollection = async (ids) => {
    try {
      const firestore = getFirestore();
      const parentDocRef = doc(firestore, 'Coupon', ids);
      const HomePage = doc(firestore, 'HomepageCoupon', ids);
      const subcollectionRef = collection(parentDocRef, ids);
      if (HomePage.id === ids) {
        await deleteDoc(HomePage);
      }
      const querySnapshot = await getDocs(subcollectionRef);

      querySnapshot.forEach((doc) => {
        deleteFileFromStorage(doc.data().CouponBgImage);
        deleteDoc(doc.ref);
      });
      const querySnapshot1 = await getDoc(parentDocRef);
      if (querySnapshot1.exists) {
        deleteFileFromStorage(querySnapshot1.data().bgImage);
        deleteFileFromStorage(querySnapshot1.data().logo);
        await deleteDoc(parentDocRef);
      }
      toast.success('Delete Coupon Successfully!');
      setItemCoupon((prevCoupons) =>
        prevCoupons.filter((coupon) => coupon.id !== ids)
      );
    } catch (error) {
      console.error('Error deleting subcollection:', error);
    }
  };

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          p: 4
        }}
      >
        {
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Stack
                direction="row"
                sx={{ display: 'contents' }}
                justifyContent="space-between"
              >
                <Stack spacing={1}>
                  <Typography variant="h4">
                    {' '}
                    {itemCoupon[0]?.data.Company?.company_Name}
                  </Typography>
                </Stack>
                <div>
                  <Button
                    onClick={() => {
                      if (open) {
                        setOpen(false);
                      } else {
                        navigat(-1);
                      }
                    }}
                    startIcon={
                      <SvgIcon fontSize="small">
                        <ArrowBackIosNewIcon />
                      </SvgIcon>
                    }
                    variant="text"
                  >
                    Back
                  </Button>
                </div>
              </Stack>
            </Grid>

            {open ? (
              <>
                <CouponEdit couponData={couponData} />
              </>
            ) : (
              itemCoupon.map((d) => (
                <Grid item xs={12} md={6} lg={4} key={d.id}>
                  <Card
                    sx={{
                      border: 'none',
                      minWidth: '355px',
                      '@media(max-width: 400px)': {
                        minWidth: '320px'
                      },
                      ':hover': {
                        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.28)'
                      }
                    }}
                  >
                    <Stack justifyContent="space-between">
                      <Box position="relative">
                        <Box
                          component="img"
                          height="180px"
                          width="100%"
                          sx={{ objectFit: 'cover' }}
                          src={d?.data?.CouponBgImage}
                        ></Box>
                        <Stack
                          sx={{
                            position: 'absolute',
                            top: '-70%',
                            left: 0,
                            bottom: 0,
                            right: '-80%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <Stack direction="row">
                            <Tooltip title="Coupon Delete">
                              <IconButton
                                onClick={() => {
                                  deleteSubcollection(d?.id);
                                }}
                                sx={{
                                  color: 'red',
                                  ':hover': {
                                    bgcolor: '#6366F1',
                                    color: 'white',
                                    marginRight: '5px'
                                  }
                                }}
                              >
                                <DeleteForeverIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Edit">
                              <IconButton
                                onClick={() => {
                                  setOpen(true);
                                  setCouponData(d);
                                }}
                                sx={{
                                  color: 'white',
                                  bgcolor: '#6366F1',
                                  ':hover': {
                                    bgcolor: '#6366F1'
                                  }
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                          </Stack>
                        </Stack>
                        <Paper
                          sx={{
                            boxShadow: '0px 4px 0px rgba(0, 0, 0, 0.2)',
                            position: 'absolute',
                            width: '100px',
                            height: '100px',
                            margin: 'auto',
                            border: 'none',
                            borderRadius: '15px',
                            top: '100%',
                            left: 0,
                            bottom: 0,
                            right: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <Box
                            component="img"
                            sx={{ objectFit: 'cover' }}
                            width="50px"
                            src={logo?.logo}
                          ></Box>
                        </Paper>
                      </Box>

                      <Stack
                        alignItems="center"
                        justifyContent="center"
                        mt={10}
                        mb={3}
                        spacing={1}
                      >
                        <Typography
                          fontSize="1rem"
                          fontWeight={600}
                          sx={{ color: '#2A3A5F' }}
                        >
                          {d?.data.BranchCard}
                        </Typography>
                        <Typography
                          fontSize={'1.4rem'}
                          fontWeight={700}
                          sx={{
                            color: '#2A3A5F',
                            '@media (max-width: 600px)': {
                              fontSize: '30px'
                            }
                          }}
                        >
                          {d?.data.discount}
                        </Typography>
                      </Stack>

                      <Box>
                        <Stack alignItems="center">
                          <Typography
                            fontSize="1rem"
                            fontWeight={600}
                            sx={{ color: '#2A3A5F' }}
                          >
                            {d?.data.year}
                          </Typography>
                        </Stack>
                        <CardActions disableSpacing>
                          <Button sx={{ cursor: 'default' }}>
                            {d.data.CouponType}
                          </Button>
                          <Button
                            sx={{ marginLeft: 'auto' }}
                            startIcon={<Visibility />}
                            onClick={() => {
                              handleCardClick(d.id);
                            }}
                          >
                            Coupon Codes
                          </Button>
                        </CardActions>
                        <Button
                          // onClick={() => navigate("/Reisen/Traveldateils")}
                          // endIcon={<CrossArrow />}
                          variant="contained"
                          fullWidth
                          style={{
                            textTransform: 'none',
                            backgroundColor: `${
                              d?.data.status == 'active'
                                ? '#6366F166'
                                : '#F1E7F0'
                            }`,
                            fontSize: '1rem',
                            fontWeight: 600,
                            color: '#5b24b2',
                            cursor: 'default'
                          }}
                        >
                          {d?.data.status == 'active' ? 'Active' : 'Inactive'}
                        </Button>
                      </Box>
                    </Stack>
                  </Card>
                </Grid>
              ))
            )}
          </Grid>
        }
        {/* <CouponModel open={open} setOpen={setOpen} itemCoupon={itemCoupon} /> */}
      </Box>
    </>
  );
};

export default CompanyDetail;
