import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  Paper,
  Stack,
  SvgIcon,
  Tooltip,
  Typography
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  setDoc,
  where
} from 'firebase/firestore';
import { db } from '../firebase';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../Store/Sclies/loaderSlice';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { toast } from 'react-hot-toast';
const HomePageCoupon = () => {
  const [couponData, setCouponData] = React.useState([]);
  const [couponList, setCoponList] = useState([]);
  const [showCompany, setShowCoupon] = useState(false);

  const dispatch = useDispatch();

  const fetchData = async () => {
    dispatch(showLoader());
    const ref = collection(db, 'HomepageCoupon');
    const querySnapshot = await getDocs(ref);

    const promises = querySnapshot.docs.map(async (doc) => {
      return doc.data();
    });

    const couponDataArray = await Promise.all(promises);
    const mergedData = couponDataArray.flat();
    setCouponData(mergedData);
    dispatch(hideLoader());
  };
  const deleteHomeCouponFirebase = async (id) => {
    try {
      const collectionRef = collection(db, 'HomepageCoupon');
      const q = query(collectionRef, where('id', '==', id));

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(async (doc) => {
        await deleteDoc(doc.ref).then(() => {
          fetchData();
          toast.success('Delete homepage coupon!');
        });
      });
    } catch (error) {
      console.error('Error deleting documents: ', error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      const ref = collection(db, 'Company');
      const ref1 = collection(db, 'Coupon');
      const querySnapshot = await getDocs(ref);
      const querySnapshot2 = await getDocs(ref1);

      const promises = querySnapshot.docs.map(async (doc) => {
        return doc.data();
      });
      const promises2 = querySnapshot2.docs.map(async (doc) => {
        return doc.data();
      });

      const couponDataArray = await Promise.all(promises);
      const couponDataArray2 = await Promise.all(promises2);
      const mergedData = couponDataArray.flat();
      const mergedData2 = couponDataArray2.flat();

      const output = [];

      mergedData.forEach((companyItem) => {
        const matchingCoupons = mergedData2.filter(
          (couponItem) => couponItem.CompanyName === companyItem.company_Name
        );
        if (matchingCoupons.length > 0) {
          matchingCoupons.forEach((matchingCoupon) => {
            output.push({
              ...matchingCoupon,
              Id: matchingCoupon.FirebaseId
            });
          });
        }
      });
      const activeOffers = output.filter((offer) => offer.status != 'deactive');
      setCoponList(activeOffers);
      // dispatch(setLoading(false));
    };

    fetchData();
  }, []);

  const AddHomeCouponFirebase = async (data, id) => {
    console.log(data);
    dispatch(showLoader()); //
    try {
      const validId = id.replace('/', '-');
      const documentRef = doc(collection(db, 'HomepageCoupon'), validId);
      await setDoc(documentRef, {
        ...data,
        id: validId
      }).then(() => {
        toast.success(' Add Coupon Successfully!');
        setShowCoupon(false);
        fetchData();
      });
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      toast.error(' Coupon not  added ');
      console.error('Error updating array in Firestore:', error);
    }
  };

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          p: 4
        }}
      >
        <Stack spacing={3} mb={4}>
          <Stack direction="row" justifyContent="space-between" spacing={4}>
            <Stack spacing={1}></Stack>
            <div>
              <Button
                onClick={() => {
                  setShowCoupon(!showCompany);
                  // setEditFrom(false);
                }}
                startIcon={
                  <SvgIcon fontSize="small">
                    <AddIcon />
                  </SvgIcon>
                }
                variant="contained"
              >
                {showCompany ? 'Show Coupon List' : 'Add new coupon'}
              </Button>
            </div>
          </Stack>
        </Stack>
        <Grid container rowSpacing={5} columnSpacing={4}>
          {showCompany
            ? couponList?.map((data) => (
                <Grid item xs={12} md={6} lg={4} key={data.Id}>
                  <Card
                    sx={{
                      border: 'none',
                      height: '100%',
                      position: 'relative',
                      maxHeight: '440px',
                      minHeight: '439px',
                      minWidth: '355px',
                      '@media(max-width: 400px)': {
                        minWidth: '320px'
                      },
                      ':hover': {
                        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.28)'
                      }
                    }}
                  >
                    <Stack justifyContent="space-between">
                      <Box position="relative">
                        <Box
                          component="img"
                          sx={{ objectFit: 'cover' }}
                          height="180px"
                          width="100%"
                          src={data?.CouponBgImage}
                        ></Box>
                        <Stack
                          sx={{
                            position: 'absolute',
                            top: '-70%',
                            left: 0,
                            bottom: 0,
                            right: '-85%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <Stack direction="row">
                            <Tooltip title="Add to home page">
                              <IconButton
                                onClick={() => {
                                  AddHomeCouponFirebase(data, data.Id);
                                }}
                                sx={{
                                  color: 'green',
                                  ':hover': {
                                    bgcolor: '#6366F1',
                                    color: 'white'
                                  }
                                }}
                              >
                                <AddCircleOutlineIcon />
                              </IconButton>
                            </Tooltip>
                          </Stack>
                        </Stack>
                        <Paper
                          sx={{
                            boxShadow: '0px 4px 0px rgba(0, 0, 0, 0.2)',
                            position: 'absolute',
                            width: '100px',
                            height: '100px',
                            margin: 'auto',
                            border: 'none',
                            borderRadius: '15px',
                            top: '100%',
                            left: 0,
                            bottom: 0,
                            right: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <Box
                            component="img"
                            width="50px"
                            sx={{ objectFit: 'cover' }}
                            src={data?.CouponLogo}
                          ></Box>
                        </Paper>
                      </Box>
                      <Stack
                        alignItems="center"
                        justifyContent="center"
                        mt={10}
                        mb={3}
                        spacing={1}
                      >
                        <Typography
                          fontSize="1rem"
                          fontWeight={600}
                          sx={{ color: '#2A3A5F' }}
                        >
                          {data?.BranchCard}
                        </Typography>
                        <Typography
                          fontSize={'1.4rem'}
                          fontWeight={700}
                          sx={{
                            color: '#2A3A5F',
                            '@media (max-width: 600px)': {
                              fontSize: '30px'
                            }
                          }}
                        >
                          {data?.discount}
                        </Typography>
                      </Stack>

                      <Box>
                        <Stack alignItems="center" mb={2}>
                          <Typography
                            fontSize="1rem"
                            fontWeight={600}
                            sx={{ color: '#2A3A5F' }}
                          >
                            {data?.year}
                          </Typography>
                        </Stack>
                        <Button
                          // onClick={() => navigate("/Reisen/Traveldateils")}
                          // endIcon={<CrossArrow />}
                          variant="contained"
                          fullWidth
                          style={{
                            position: 'absolute',
                            textTransform: 'none',
                            backgroundColor: `${
                              data?.status == 'active' ? '#6366F166' : '#F1E7F0'
                            }`,
                            fontSize: '1rem',
                            fontWeight: 600,
                            color: '#5b24b2',
                            bottom: 0
                          }}
                        >
                          {data?.status == 'active' ? 'Active' : 'Inactive'}
                        </Button>
                      </Box>
                    </Stack>
                  </Card>
                </Grid>
              ))
            : couponData?.map((d, index) => (
                <Grid item xs={12} md={6} lg={4} key={index.toString()}>
                  <Card
                    sx={{
                      border: 'none',
                      height: '100%',
                      position: 'relative',
                      maxHeight: '440px',
                      minHeight: '439px',
                      minWidth: '355px',
                      '@media(max-width: 400px)': {
                        minWidth: '320px'
                      },
                      ':hover': {
                        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.28)'
                      }
                    }}
                  >
                    <Stack justifyContent="space-between">
                      <Box position="relative">
                        <Box
                          component="img"
                          height="180px"
                          width="100%"
                          sx={{ objectFit: 'cover' }}
                          src={d?.CouponBgImage}
                        ></Box>
                        <Stack
                          sx={{
                            position: 'absolute',
                            top: '-70%',
                            left: 0,
                            bottom: 0,
                            right: '-85%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <Tooltip title="Delete">
                            <IconButton
                              onClick={() => {
                                deleteHomeCouponFirebase(d?.id);
                              }}
                              sx={{
                                color: 'red',
                                ':hover': {
                                  bgcolor: '#6366F1',
                                  color: 'white'
                                }
                              }}
                            >
                              <DeleteForeverIcon />
                            </IconButton>
                          </Tooltip>
                        </Stack>
                        <Paper
                          sx={{
                            boxShadow: '0px 4px 0px rgba(0, 0, 0, 0.2)',
                            position: 'absolute',
                            width: '100px',
                            height: '100px',
                            margin: 'auto',
                            border: 'none',
                            borderRadius: '15px',
                            top: '100%',
                            left: 0,
                            bottom: 0,
                            right: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <Box
                            component="img"
                            width="50px"
                            sx={{ objectFit: 'cover' }}
                            src={d.CouponLogo}
                          ></Box>
                        </Paper>
                      </Box>
                      <Stack
                        alignItems="center"
                        justifyContent="center"
                        mt={10}
                        mb={3}
                        spacing={1}
                      >
                        <Typography
                          fontSize="1rem"
                          fontWeight={600}
                          sx={{ color: '#2A3A5F' }}
                        >
                          {d?.BranchCard}
                        </Typography>
                        <Typography
                          fontSize={'1.4rem'}
                          fontWeight={700}
                          sx={{
                            color: '#2A3A5F',
                            '@media (max-width: 600px)': {
                              fontSize: '30px'
                            }
                          }}
                        >
                          {d?.discount}
                        </Typography>
                      </Stack>

                      <Box>
                        <Stack alignItems="center" mb={2}>
                          <Typography
                            fontSize="1rem"
                            fontWeight={600}
                            sx={{ color: '#2A3A5F' }}
                          >
                            {d?.year}
                          </Typography>
                        </Stack>
                        <Button
                          // onClick={() => navigate("/Reisen/Traveldateils")}
                          // endIcon={<CrossArrow />}
                          variant="contained"
                          fullWidth
                          style={{
                            position: 'absolute',
                            bottom: 0,
                            textTransform: 'none',
                            backgroundColor: `${
                              d?.status == 'active' ? '#6366F166' : '#F1E7F0'
                            }`,
                            fontSize: '1rem',
                            fontWeight: 600,
                            color: '#5b24b2'
                          }}
                        >
                          {d?.status == 'active' ? 'Active' : 'Inactive'}
                        </Button>
                      </Box>
                    </Stack>
                  </Card>
                </Grid>
              ))}
        </Grid>
        {/* <CouponModel open={open} setOpen={setOpen} itemCoupon={itemCoupon} /> */}
      </Box>
    </>
  );
};

export default HomePageCoupon;
