import React from 'react';
import Chart from 'react-apexcharts';

import { Card, CardContent, CardHeader, Divider } from '@mui/material';

export const OverviewChart = ({ sx, genders }) => {
  const chartData = {
    series: [
      genders.filter((gender) => gender == 'male').length,
      genders.filter((gender) => gender == 'female').length,
      genders.filter((gender) => gender == 'other').length
    ],
    labels: ['MALE', 'FEMALE', 'PREFER NOT TO SAY']
  };
  const chartOptions = {
    colors: ['#2E93fA', '#E91E63', '#FF9800'],
    chart: {
      type: 'donut'
    },
    labels: chartData.labels,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: '100%',
            height: 'auto'
          },
          legend: {
            position: 'bottom'
          }
        }
      }
    ]
  };

  return (
    <Card sx={sx}>
      <CardHeader title="USERS" />
      <CardContent>
        <Chart
          options={chartOptions}
          series={chartData.series}
          type="donut"
          width="480"
        />
      </CardContent>
      <Divider />
    </Card>
  );
};
