import React from 'react';

import {
  Box,
  Typography,
  Unstable_Grid2 as Grid,
  Button,
  TextField,
  Stack
} from '@mui/material';

import logo from './../../Assets/Logo.png';
import Image from './../../Assets/auth-illustration.svg';
import { NavLink, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { toast } from 'react-hot-toast';
const AuthLayout = () => {
  const navigate = useNavigate();
  const [loginData, setLoginData] = useState([]);
  useEffect(() => {
    const getLoginData = async () => {
      const ref = collection(db, 'AdminPanelUser');
      const querySnapshot = await getDocs(ref);

      const data = querySnapshot.docs.map((doc) => doc.data());
      setLoginData(data);
    };
    getLoginData();
  }, []);
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      submit: null
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Must be a valid email')
        .max(255)
        .required('Email is required'),
      password: Yup.string().max(255).required('Password is required')
    }),
    onSubmit: async (values) => {
      var loginUser = loginData.find(
        (obj) => obj.email === values.email && obj.password === values.password
      );

      if (loginUser) {
        localStorage.setItem(
          'User',
          JSON.stringify({
            email: loginUser.email,
            role: loginUser.role
          })
        );
        toast.success('welcome to Gogaudi AdminPanel');
        navigate('/');
      } else {
        toast.error('please check password and email id!');
      }
    }
  });

  return (
    <Box
      component="main"
      sx={{
        display: 'flex',
        flex: '1'
      }}
    >
      <Grid container sx={{ flex: '1' }}>
        <Grid
          xs={12}
          lg={6}
          sx={{
            backgroundColor: 'background.paper',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative'
          }}
        >
          <Box
            component="header"
            sx={{
              left: 0,
              p: 3,
              position: 'fixed',
              top: 0,
              width: '100%'
            }}
          >
            <Box
              component={NavLink}
              to="/"
              sx={{
                display: 'inline-flex'
              }}
            >
              <img
                src="/GoLogo.svg"
                width={120}
                height={40}
                alt="Picture of the author"
              />
            </Box>
          </Box>
          <Box
            sx={{
              backgroundColor: 'background.paper',
              flex: '1 1 auto',
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Box
              sx={{
                maxWidth: 550,
                px: 3,
                py: '100px',
                width: '100%'
              }}
            >
              <div>
                <Stack spacing={1} sx={{ mb: 3 }}>
                  <Typography variant="h4">Login</Typography>
                </Stack>
                <form noValidate onSubmit={formik.handleSubmit}>
                  <Stack spacing={3}>
                    <TextField
                      error={!!(formik.touched.email && formik.errors.email)}
                      fullWidth
                      helperText={formik.touched.email && formik.errors.email}
                      label="Email Address"
                      name="email"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="email"
                      value={formik.values.email}
                    />
                    <TextField
                      error={
                        !!(formik.touched.password && formik.errors.password)
                      }
                      fullWidth
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                      label="Password"
                      name="password"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="password"
                      value={formik.values.password}
                    />
                  </Stack>
                  {formik.errors.submit && (
                    <Typography color="error" sx={{ mt: 3 }} variant="body2">
                      {formik.errors.submit}
                    </Typography>
                  )}
                  <Button
                    fullWidth
                    size="large"
                    sx={{ mt: 3 }}
                    type="submit"
                    variant="contained"
                  >
                    Continue
                  </Button>
                </form>
              </div>
            </Box>
          </Box>
        </Grid>
        <Grid
          xs={12}
          lg={6}
          sx={{
            alignItems: 'center',
            background:
              'radial-gradient(50% 50% at 50% 50%, #122647 0%, #090E23 100%)',
            color: 'white',
            display: 'flex',
            justifyContent: 'center',
            '& img': {
              maxWidth: '100%'
            }
          }}
        >
          <Box sx={{ p: 3, height: '100vh' }}>
            <Typography
              align="center"
              color="inherit"
              sx={{
                fontSize: '24px',
                lineHeight: '32px',
                mb: 1
              }}
              variant="h1"
            >
              Welcome to{' '}
              <Box component="a" sx={{ color: '#15B79E' }} target="_blank">
                Gogaudi Admin Panel
              </Box>
            </Typography>
            <img alt="" src={Image} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AuthLayout;
