import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Box,
  Divider,
  Drawer,
  Stack,
  SvgIcon,
  // Typography,
  useMediaQuery
} from '@mui/material';
// import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import ApartmentIcon from '@mui/icons-material/Apartment';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import CategoryIcon from '@mui/icons-material/Category';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

import SideNavItem from './side-nav-item';
import { NavLink, useLocation } from 'react-router-dom';
import Scrollbar from '../../components/scrollbar';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../firebase';
import { useDispatch } from 'react-redux';
import { setString } from '../../Store/Sclies/adminTypeSlice';
const SideNav = (props) => {
  const [items, setItems] = useState([]);
  const dispatch = useDispatch();

  const fetchUserType = async () => {
    try {
      const dataRef = collection(db, 'AdminPanelUser');
      const loginUser = JSON.parse(localStorage.getItem('User'));
      const q = query(dataRef, where('email', '==', loginUser.email));
      const querySnapshot = await getDocs(q);
      const data = querySnapshot.docs.map((doc) => doc.data());
      setItems(data[0].role === 'admin' ? AdminItems : CuratorItems);
      dispatch(setString(data[0].role));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    fetchUserType();
  }, []);
  const AdminItems = [
    {
      title: 'Category',
      path: '/category',
      icon: (
        <SvgIcon fontSize="small">
          <CategoryIcon />
        </SvgIcon>
      )
    },
    {
      title: 'Companies',
      path: '/company',
      icon: (
        <SvgIcon fontSize="small">
          <ApartmentIcon />
        </SvgIcon>
      )
    },

    {
      title: 'Add Coupons',
      path: '/offercoupon',
      icon: (
        <SvgIcon fontSize="small">
          <LocalOfferIcon />
        </SvgIcon>
      )
    },
    {
      title: 'Age Verification',
      path: '/curator',
      icon: (
        <SvgIcon fontSize="small">
          <FactCheckIcon />
        </SvgIcon>
      )
    },
    {
      title: 'Users',
      path: '/users',
      icon: (
        <SvgIcon fontSize="small">
          <PeopleAltIcon />
        </SvgIcon>
      )
    },
    {
      title: 'Add Curator',
      path: '/addcurator',
      icon: (
        <SvgIcon fontSize="small">
          <FactCheckIcon />
        </SvgIcon>
      )
    },
    {
      title: 'Landing Page Coupons',
      path: '/homepagecoupon',
      icon: (
        <SvgIcon fontSize="small">
          <FactCheckIcon />
        </SvgIcon>
      )
    }
  ];
  const CuratorItems = [
    {
      title: 'Curator',
      path: '/curator',
      icon: (
        <SvgIcon fontSize="small">
          <FactCheckIcon />
        </SvgIcon>
      )
    }
  ];

  const { open, onClose } = props;
  const location = useLocation();
  // const pathname = usePathname();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  const content = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': {
          height: '100%'
        },
        '& .simplebar-scrollbar:before': {
          background: 'neutral.400'
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <Box sx={{ p: 3 }}>
          <Box
            component={NavLink}
            to="/"
            sx={{
              display: 'inline-flex'
            }}
          >
            <img
              src="/logo-new.svg"
              width={150}
              height={40}
              alt="Picture of the author"
            />
          </Box>
          {/* <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.04)',
              borderRadius: 1,
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'space-between',
              mt: 2,
              p: '12px'
            }}
          >
            <div>
              <Typography color="inherit" variant="subtitle1">
                GoGaudi
              </Typography>
              <Typography color="neutral.400" variant="body2">
                Admin Panel
              </Typography>
            </div>
            <SvgIcon fontSize="small" sx={{ color: 'white' }}>
              <KeyboardDoubleArrowUpIcon />
            </SvgIcon>
          </Box> */}
        </Box>
        <Divider sx={{ borderColor: 'white' }} />
        <Box
          component="nav"
          sx={{
            flexGrow: 1,
            px: 2,
            py: 3
          }}
        >
          <Stack
            component="ul"
            spacing={0.5}
            sx={{
              listStyle: 'none',
              p: 0,
              m: 0
            }}
          >
            {items.map((item) => {
              const active = item.path
                ? location.pathname === item.path
                : false;

              return (
                <SideNavItem
                  active={active}
                  disabled={item.disabled}
                  external={item.external}
                  icon={item.icon}
                  key={item.title}
                  path={item.path}
                  title={item.title}
                />
              );
            })}
          </Stack>
        </Box>
        <Divider sx={{ borderColor: 'white' }} />
      </Box>
    </Scrollbar>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: '#5b24b2',
            color: 'common.white',
            width: 280
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: '#5b24b2',
          color: 'common.white',
          width: 280
        }
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

SideNav.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};
export default SideNav;
