import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import mySliceReducer from './Sclies/CatagorySlice';
import { companyReducer } from './Sclies/CamapanySlice';
import loaderReducer from './Sclies/loaderSlice';
import stringReducer from './Sclies/adminTypeSlice';
const store = configureStore({
  reducer: {
    catagory: mySliceReducer,
    company: companyReducer,
    loader: loaderReducer,
    string: stringReducer
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false
  })
});

export default store;
