import React from 'react';
import './App.css';
import { ThemeProvider } from '@mui/material';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import CreateTheme from './theme/index';
import Layout from './layouts/dashboard/layout';
import Company from './pages/companies';
import Curator from './pages/Curator';
import OfferCoupon from './pages/OfferCoupon';
import Category from './pages';
import PrivateRoute from './Routes/PrivateRoute';
import AuthLayout from './layouts/auth/layout';
import ProtectRoute from './Routes/ProtectRouter';
import { useSelector } from 'react-redux';
import AddCurator from './pages/AddCurator';
import CompanyDetail from './pages/CompanyDetail';
import Users from './pages/Users';
import NotFound from './pages/404';
import HomePageCoupon from './pages/HomePageCoupon';
import OverView from './pages/OverView';
import AddMultipleCode from './pages/addMultipleCode';
import AddMutipleCode from './pages/addMultipleCode';
// import { getAuth } from 'firebase/auth';

// const Login = lazy(() => import("./Page/Login"));
// const SignUp = lazy(() => import("./Page/SignUp"));
// const Travel = lazy(() => import("./Page/Travel/Index"));
// const Dashboard = lazy(() => import("./Page/Dashboard"));
// const Layout = lazy(() => import("./Comonents/Layout"));

// const ConfrimAge = lazy(() => import("./Page/ConfrimAge"));
// const Traveldateils = lazy(() => import("./Page/Travel/Traveldateils"));
// const Password = lazy(() => import("./Page/Password"));

// useEffect(() => {
// });

function App() {
  const theme = CreateTheme();
  const isAdmin = useSelector((state) => state.string);

  return (
    <>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route
              path="/login"
              element={<ProtectRoute element={<AuthLayout />} />}
            />
            <Route element={<PrivateRoute element={<Layout />} />}>
              <Route path="/category" element={<Category />} />
              <Route path="company" element={<Company />} />
              <Route path="offercoupon" element={<OfferCoupon />} />
              <Route path="/curator" element={<Curator />} />
              <Route path="/AddMultipleCode" element={<AddMultipleCode />} />
              <Route path="/homepagecoupon" element={<HomePageCoupon />} />
              <Route path="/" element={<OverView />} />
              {isAdmin === 'admin' && (
                <Route path="/addCurator" element={<AddCurator />} />
              )}
              <Route path="/company/:id" element={<CompanyDetail />} />
              <Route
                path="/company/:id/:couponData"
                element={<AddMutipleCode />}
              />
              <Route path="users" element={<Users />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </BrowserRouter>
        <Toaster
          position="bottom-right"
          toastOptions={{
            success: {
              iconTheme: {
                primary: 'white',
                secondary: 'green'
              },
              style: {
                background: '#2ed422'
              }
            },
            error: {
              iconTheme: {
                primary: 'white',
                secondary: 'red'
              },
              style: {
                background: 'red'
              }
            },
            style: {
              color: 'white',
              fontWeight: '600',
              padding: '10px 30px 10px 30px'
            }
          }}
        />
      </ThemeProvider>
    </>
  );
}

export default App;
