import React from 'react';
import { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Unstable_Grid2 as Grid,
  IconButton,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  FormLabel
} from '@mui/material';
import Selects from 'react-select';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Stack } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCompanyData } from '../../Store/Sclies/CamapanySlice';
import { addDoc, collection, getDocs, updateDoc } from 'firebase/firestore';
import { db, storage } from '../../firebase';
import { toast } from 'react-hot-toast';
import { hideLoader, showLoader } from '../../Store/Sclies/loaderSlice';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { updateArray } from '../../Store/Sclies/CatagorySlice';
import CSVReader from 'react-csv-reader';
import '../AddMultipleCode/multipleCode.css';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// Age Verification
// const coupon_Type = [
//   {
//     value: 'Protective',
//     label: 'Protective'
//   },
//   {
//     value: 'Retroactive',
//     label: 'Retroactive'
//   },
//   {
//     value: 'Open',
//     label: 'Open'
//   }
// ];

const coupon_Type = [
  {
    value: 'Required',
    label: 'Required'
  },
  {
    value: 'Not Required',
    label: 'Not Required'
  }
];

const couponCode_Type = [
  {
    value: 'Link',
    label: 'Link'
  },
  {
    value: 'Single Code',
    label: 'Single Code'
  },
  {
    value: 'Multiple Code',
    label: 'Multiple Codes'
  }
];

const CuoponForm = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.company);
  const category = useSelector((state) => state.catagory.items);
  const isLoading = useSelector((state) => state.loader);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedLogo, setSelectedLogo] = useState(null);
  const [previewLogoUrl, setPreviewLogoUrl] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [previewUrlFile, setPreviewUrlFile] = useState(null);

  const [codeFile, setCodeFile] = useState(null);
  const [timeMilisecond, setTimeMilisecond] = useState(0);

  const [error, setError] = useState(false);

  const sortedData = data.slice().sort((a, b) => {
    const nameA = a.company_Name.toLowerCase();
    const nameB = b.company_Name.toLowerCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  });

  const hourOptions = [...Array(72).keys()].map((hour) => ({
    value: `${hour}`.padStart(2, '0'),
    label: `${hour}`.padStart(2, '0')
  }));

  const minuteOptions = [...Array(60).keys()].map((minute) => ({
    value: `${minute}`.padStart(2, '0'),
    label: `${minute}`.padStart(2, '0')
  }));
  const [selectedHour, setSelectedHour] = useState(null);
  const [selectedMinute, setSelectedMinute] = useState(null);

  const handleHourChange = (selectedOption) => {
    setSelectedHour(selectedOption);
  };

  const handleMinuteChange = (selectedOption) => {
    setSelectedMinute(selectedOption);
  };

  const millisecondsInHour = 60 * 60 * 1000;
  const millisecondsInMinutes = 60 * 1000;

  useEffect(() => {
    if (selectedHour && selectedMinute) {
      const hourMiliseconds = selectedHour.value * millisecondsInHour;
      const minuteMiliseconds = selectedMinute.value * millisecondsInMinutes;
      const totalMiliseconds = hourMiliseconds + minuteMiliseconds;
      setTimeMilisecond(totalMiliseconds);
    }
  }, [selectedHour, selectedMinute]);

  const customHourFilter = (option, inputValue) => {
    const numericInputValue = Number(inputValue);
    return (
      option.label.startsWith(inputValue) &&
      numericInputValue >= 0 &&
      numericInputValue < 72
    );
  };

  const customMinuteFilter = (option, inputValue) => {
    const numericInputValue = Number(inputValue);
    return (
      option.label.startsWith(inputValue) &&
      numericInputValue >= 0 &&
      numericInputValue < 60
    );
  };
  const handleHourKeyDown = (e) => {
    if (!/^\d$/.test(e.key)) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    dispatch(fetchCompanyData());
    fetchData();
  }, [dispatch]);
  const fetchData = async () => {
    try {
      const arr = [];
      const ref = collection(db, 'Admin/AdminPanelSubCol/Category');
      const querySnapshot = await getDocs(ref);
      let i = 1;
      querySnapshot.forEach((doc) => {
        arr.push({ id: i, Name: doc.data().name });
        i++;
      });
      dispatch(updateArray(arr));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const [values, setValues] = useState({
    CompanyName: '',
    CompanyType: '',
    BranchCard: '',
    Termsandconditions: '',
    discount: '',
    group: '',
    work: '',
    year: '',
    Category: '',
    CouponType: '',
    CouponCode: '',
    urlLink: ''
  });

  const handleChange = useCallback((event) => {
    setValues((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value
    }));
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (selectedImage && selectedLogo) {
      setError(false);
      dispatch(showLoader());
      try {
        const {
          CompanyName,
          CompanyType,
          BranchCard,
          discount,
          group,
          year,
          Category,
          CouponType,
          CouponCode,
          urlLink,
          work,
          Termsandconditions
        } = values;
        const codeValue = CouponCode;
        console.log(selectedLogo);
        const storageRef = ref(storage, `Coupon/${selectedImage.name}`);
        const storageRefLogo = ref(storage, `CouponLogo/${selectedLogo.name}`);

        await uploadBytes(storageRef, selectedImage);
        await uploadBytes(storageRefLogo, selectedLogo);

        const downloadURL = await getDownloadURL(storageRef);
        const downloadLogoURL = await getDownloadURL(storageRefLogo);
        const documentRef = collection(db, `Coupon`);

        const newDocRef = await addDoc(documentRef, {
          CompanyName,
          CompanyType,
          BranchCard,
          Termsandconditions,
          discount,
          group,
          work,
          year,
          status: 'active',
          Category,
          CouponType,
          urlLink: urlLink,
          SingleCode: {
            code: codeValue,
            creationDate: new Date().valueOf(),
            UserId: []
          },
          Link: {
            creationDate: new Date().valueOf(),
            UserId: []
          },
          selectedTime: timeMilisecond,
          CouponBgImage: downloadURL,
          CouponLogo: downloadLogoURL,
          CouponCodeFile: codeFile,
          timestamp: new Date()
        });
        // .then(() => {
        //   setSelectedImage(null);
        // });

        const firebaseId = newDocRef.id;

        // Now update the document with the generated Firestore ID
        await updateDoc(newDocRef, { FirebaseId: firebaseId }).then(() => {
          setPreviewUrl(null);
          setPreviewLogoUrl(null);
          setSelectedLogo(null);
          setSelectedImage(null);
          setCodeFile(null);
          setPreviewUrlFile(null);
          setSelectedMinute(null);
          setSelectedHour(null);
          setValues({
            Category: '',
            CompanyName: '',
            CompanyType: '',
            CouponType: '',
            BranchCard: '',
            discount: '',
            year: '',
            Termsandconditions: '',
            urlLink: '',
            group: '',
            work: '',
            CouponCode: '',
            selectedTime: ''
          });
        });
        dispatch(hideLoader());
        toast.success('Coupon added successfully');
        // });
      } catch (error) {
        console.error(error);
        dispatch(hideLoader());
        toast.error('Coupon not added ');
      }
    } else {
      setError(true);
    }
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setError(false);
      setSelectedImage(file);

      const reader = new FileReader();
      reader.onload = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleLogoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setError(false);
      setSelectedLogo(file);

      const reader = new FileReader();
      reader.onload = () => {
        console.log(reader.result);
        setPreviewLogoUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setError(false);
      setPreviewUrlFile(file.name);

      const reader = new FileReader();
      reader.readAsDataURL(file);
    }
  };

  const papaparseOptions = {
    header: false,
    dynamicTyping: true,
    skipEmptyLines: true
  };

  const handleForce = (data) => {
    const transformedData =
      data &&
      data.map((item, index) => {
        let timeUpdate = timeMilisecond;
        const date = new Date().valueOf();
        if (index === -1) {
          return { data1: item[0] };
        } else {
          return {
            code: item[0],
            userId: '',
            creation_Date: date,
            expire: timeUpdate,
            expire_Time: '',
            CodeUsedDate: ''
          };
        }
      });
    setCodeFile(transformedData);
  };
  useEffect(() => {
    handleForce(codeFile);
  }, [timeMilisecond]);

  const handleHowItWorks = (value) => {
    setValues((prevState) => ({
      ...prevState,
      work: value
    }));
    console.log(values);
  };
  const handleTermsAndConditions = (value) => {
    setValues((prevState) => ({
      ...prevState,
      Termsandconditions: value
    }));
    console.log(values);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <CardHeader title="Coupon" />
        <CardContent sx={{ pt: 0 }}>
          <Box sx={{ m: -1.5 }}>
            <Grid container spacing={3}>
              <Grid xs={12} md={6}>
                <FormControl
                  variant="filled"
                  fullWidth
                  style={{
                    backgroundColor: values.Category ? '#EBEEFE' : 'transparent'
                  }}
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    Category
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="Category"
                    value={values.Category}
                    onChange={handleChange}
                    MenuProps={{
                      style: {
                        height: '300px',
                        scrollBehavior: 'smooth'
                      }
                    }}
                  >
                    <MenuItem value="">None</MenuItem>
                    {category.map((option, index) => (
                      <MenuItem key={index.toString()} value={option.Name}>
                        {option.Name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={12} md={6}>
                <FormControl
                  variant="filled"
                  fullWidth
                  style={{
                    backgroundColor: values.CompanyName
                      ? '#EBEEFE'
                      : 'transparent'
                  }}
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    Company
                  </InputLabel>
                  <Select
                    required
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="CompanyName"
                    value={values.CompanyName}
                    onChange={handleChange}
                    MenuProps={{
                      style: {
                        height: '300px',
                        scrollBehavior: 'smooth'
                      }
                    }}
                  >
                    <MenuItem value="">none</MenuItem>
                    {sortedData.map((option, index) => (
                      <MenuItem key={index} value={option.company_Name}>
                        {option.company_Name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={12} md={6}>
                <FormControl
                  variant="filled"
                  fullWidth
                  style={{
                    backgroundColor: values.CompanyType
                      ? '#EBEEFE'
                      : 'transparent'
                  }}
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    Age Verification
                  </InputLabel>
                  <Select
                    required
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="CompanyType"
                    value={values.CompanyType}
                    onChange={handleChange}
                  >
                    {coupon_Type.map((option, index) => (
                      <MenuItem key={index.toString()} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Description (Big Coupon)"
                  name="BranchCard"
                  onChange={handleChange}
                  required
                  value={values.BranchCard}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Discount in %"
                  name="discount"
                  onChange={handleChange}
                  required
                  value={values.discount}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <FormControl
                  variant="filled"
                  fullWidth
                  style={{
                    backgroundColor: values.CouponType
                      ? '#EBEEFE'
                      : 'transparent'
                  }}
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    Coupon Type
                  </InputLabel>
                  <Select
                    required
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="CouponType"
                    value={values.CouponType}
                    onChange={handleChange}
                  >
                    {couponCode_Type.map((option, index) => (
                      <MenuItem key={index.toString()} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid xs={12} md={6}>
                <TextField
                  required
                  fullWidth
                  label="Description (Small and Mini Coupon)"
                  name="group"
                  onChange={handleChange}
                  type="text"
                  value={values.group}
                />
              </Grid>
              <Grid xs={12} md={6}>
                {/* <TextField
                  fullWidth
                  // required
                  label="Minimum Age (Displayed Company Name)"
                  name="year"
                  onChange={handleChange}
                  type="text"
                  value={values.year}
                /> */}
              </Grid>
              {values.CouponType === 'Link' && (
                <>
                  <Grid xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Link of the company"
                      name="urlLink"
                      onChange={handleChange}
                      required
                      value={values.urlLink}
                    />
                  </Grid>
                  <Grid xs={12} md={6}></Grid>
                </>
              )}
              {values.CouponType === 'Single Code' && (
                <>
                  <Grid xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Single Code"
                      name="CouponCode"
                      onChange={handleChange}
                      required
                      value={values.CouponCode}
                    />
                  </Grid>
                  <Grid xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Link of the company"
                      name="urlLink"
                      onChange={handleChange}
                      required
                      value={values.urlLink}
                    />
                  </Grid>
                </>
              )}

              {values.CouponType === 'Multiple Code' && (
                <>
                  <Grid xs={12} md={6}>
                    <FormLabel
                      style={{ marginBottom: '10px', marginLeft: '15px' }}
                    >
                      Countdown to next code generation
                    </FormLabel>
                    <div className="time-picker-container">
                      <div className="time-picker-dropdown">
                        <FormLabel>Hour:</FormLabel>
                        <Selects
                          value={selectedHour}
                          onChange={handleHourChange}
                          options={hourOptions}
                          placeholder="HH"
                          isSearchable={true}
                          isClearable={true}
                          onKeyDown={handleHourKeyDown}
                          filterOption={customHourFilter}
                          className="time-picker-time hour-dropdown"
                          classNamePrefix="time-picker-time"
                          menuPlacement="bottom"
                          menuShouldScrollIntoView={false}
                          components={{
                            MenuList: ({ children, maxHeight }) => (
                              <div style={{ maxHeight }}>{children}</div>
                            )
                          }}
                        />
                      </div>
                      <div className="time-picker-dropdown">
                        <FormLabel>Minute:</FormLabel>
                        <Selects
                          value={selectedMinute}
                          onChange={handleMinuteChange}
                          options={minuteOptions}
                          placeholder="MM"
                          isSearchable={true}
                          isClearable={true}
                          onKeyDown={handleHourKeyDown}
                          filterOption={customMinuteFilter}
                          classNamePrefix="time-picker-time"
                          menuPlacement="bottom"
                          menuShouldScrollIntoView={false}
                          components={{
                            MenuList: ({ children, maxHeight }) => (
                              <div style={{ maxHeight }}>{children}</div>
                            )
                          }}
                        />
                      </div>
                    </div>
                  </Grid>

                  <Grid xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Link of the company"
                      name="urlLink"
                      onChange={handleChange}
                      required
                      value={values.urlLink}
                    />
                  </Grid>

                  <Grid xs={12} md={6}>
                    <Stack
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-evenly"
                    >
                      <Stack sx={{ alignItems: 'center', width: '150px' }}>
                        <label
                          onChange={handleFileUpload}
                          htmlFor="raised-button-file2"
                          accessKey=".csv"
                        >
                          <CSVReader
                            cssClass="react-csv-input d-none"
                            label="Select CSV with secret Death Star statistics"
                            onFileLoaded={handleForce}
                            parserOptions={papaparseOptions}
                            inputId="raised-button-file2"
                          />
                        </label>
                        <IconButton sx={{ width: '50px', height: '50px' }}>
                          <label htmlFor="raised-button-file2">
                            <CloudUploadIcon color="primary" />
                          </label>
                        </IconButton>
                        <Typography>Upload Csv File</Typography>
                        {/* {error && ( */}
                        {/* <Typography style={{ color: 'red' }}>
                          Please select an image.
                        </Typography> */}
                        {/* )} */}
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid xs={12} md={6}>
                    <Stack
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-evenly"
                    >
                      {previewUrlFile && (
                        <Card
                          sx={{
                            alignItems: 'center'
                          }}
                        >
                          <CardContent>
                            <p>{previewUrlFile}</p>
                          </CardContent>
                        </Card>
                      )}
                    </Stack>
                  </Grid>
                </>
              )}
              <Grid xs={12} md={6}>
                <Typography style={{ marginBottom: '10px', fontSize: '20px' }}>
                  How it works
                </Typography>
                <ReactQuill
                  theme="snow" // Choose a theme - snow is one of the themes provided by Quill
                  value={values.work}
                  onChange={handleHowItWorks}
                  style={{ height: '200px', marginBottom: '30px' }}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <Typography style={{ marginBottom: '10px', fontSize: '20px' }}>
                  Terms and Conditions
                </Typography>
                <ReactQuill
                  theme="snow" // Choose a theme - snow is one of the themes provided by Quill
                  value={values.Termsandconditions}
                  onChange={handleTermsAndConditions}
                  style={{ height: '200px', marginBottom: '30px' }}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <Stack
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="space-evenly"
                >
                  <Stack sx={{ alignItems: 'center', width: '150px' }}>
                    <input
                      key={Math.random()}
                      onChange={handleImageUpload}
                      accept="image/*"
                      style={{ display: 'none' }}
                      id="raised-button-file"
                      type="file"
                    />
                    <IconButton sx={{ width: '50px', height: '50px' }}>
                      <label htmlFor="raised-button-file">
                        <CloudUploadIcon color="primary" />
                      </label>
                    </IconButton>
                    <Typography>Background Image</Typography>
                    {error && (
                      <Typography style={{ color: 'red' }}>
                        Please select an image.
                      </Typography>
                    )}
                  </Stack>
                  {previewUrl && (
                    <Card
                      sx={{
                        alignItems: 'center'
                      }}
                    >
                      <CardContent>
                        <img
                          src={previewUrl}
                          alt="Image Preview."
                          height="100px"
                          width="400px"
                        />
                      </CardContent>
                    </Card>
                  )}
                </Stack>
              </Grid>
              <Grid xs={12} md={6}>
                <Stack
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="space-evenly"
                >
                  <Stack sx={{ alignItems: 'center', width: '150px' }}>
                    <input
                      key={Math.random()}
                      onChange={handleLogoUpload}
                      accept="image/*"
                      style={{ display: 'none' }}
                      id="raised-button-logo"
                      type="file"
                    />
                    <IconButton sx={{ width: '50px', height: '50px' }}>
                      <label htmlFor="raised-button-logo">
                        <CloudUploadIcon color="primary" />
                      </label>
                    </IconButton>
                    <Typography>Company Logo</Typography>
                    {error && (
                      <Typography style={{ color: 'red' }}>
                        Please select an image.
                      </Typography>
                    )}
                  </Stack>
                  {previewLogoUrl && (
                    <Card
                      sx={{
                        alignItems: 'center'
                      }}
                    >
                      <CardContent>
                        <img
                          src={previewLogoUrl}
                          alt="Logo Preview."
                          height="100px"
                          width="100px"
                        />
                      </CardContent>
                    </Card>
                  )}
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button variant="contained" type="submit" disabled={isLoading}>
            Submit
          </Button>
        </CardActions>
      </Card>
    </form>
  );
};
export default CuoponForm;
