import React, { useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Stack,
  SvgIcon,
  Typography
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CompanyCoupenCode from '../sections/AddMultipleCode/CompanyCoupenCode';
import MultipleCodeForm from '../sections/AddMultipleCode/MultipleCodeForm';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import DiscountIcon from '@mui/icons-material/Discount';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useNavigate } from 'react-router-dom';

const AddMutipleCode = () => {
  const [showForms, setShowForms] = useState(false);
  const [couponCodeTypeFromChild, setCouponCodeTypeFromChild] = useState('');
  const [couponCodeTypeFromChild2, setCouponCodeTypeFromChild2] = useState('');
  const [couponCodeTypeFromChild3, setCouponCodeTypeFromChild3] = useState('');
  const [couponCodeTypeFromChild4, setCouponCodeTypeFromChild4] = useState('');
  const navigate = useNavigate();

  const handleCouponCodeTypeFromChild = (couponCodeType) => {
    setCouponCodeTypeFromChild(couponCodeType);
  };

  const handleCouponCodeTypeFromChild2 = (couponCodeType) => {
    setCouponCodeTypeFromChild2(couponCodeType);
  };
  const handleCouponCodeTypeFromChild3 = (couponCodeType) => {
    setCouponCodeTypeFromChild3(couponCodeType);
  };

  const handleCouponCodeTypeFromChild4 = (couponCodeType) => {
    setCouponCodeTypeFromChild4(couponCodeType);
  };

  const avatarColor =
    couponCodeTypeFromChild === 'Multiple Code'
      ? 'warning.main'
      : 'primary.main';

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          pb: 8
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'start',
            marginBottom: '15px',
            marginLeft: '15px'
          }}
        >
          <Button
            onClick={() => navigate(-1)}
            startIcon={
              <SvgIcon fontSize="small">
                <ArrowBackIosNewIcon />
              </SvgIcon>
            }
            variant="text"
          >
            Back
          </Button>
        </div>
        <Container maxWidth="lg"></Container>
        <Container maxWidth="lg">
          <Grid
            container
            spacing={3}
            sx={{ display: 'flex', justifyContent: 'left' }}
          >
            {couponCodeTypeFromChild === 'Multiple Code' && (
              <Grid
                xs={12}
                sm={6}
                lg={couponCodeTypeFromChild === 'Multiple Code' ? 3 : 4}
                sx={{ margin: '10px' }}
              >
                <Card sx={{ height: '100%' }}>
                  <CardContent>
                    <Stack
                      alignItems="flex-start"
                      direction="row"
                      justifyContent="space-between"
                      spacing={3}
                    >
                      <Stack spacing={1}>
                        <Typography color="text.secondary" variant="overline">
                          Users
                        </Typography>
                        <Typography variant="h4">
                          {couponCodeTypeFromChild2}
                        </Typography>
                      </Stack>
                      <Avatar
                        sx={{
                          backgroundColor: 'primary.main',
                          height: 56,
                          width: 56
                        }}
                      >
                        <SvgIcon>
                          <PeopleAltIcon />
                        </SvgIcon>
                      </Avatar>
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            )}
            {couponCodeTypeFromChild === 'Single Code' && (
              <Grid
                xs={12}
                sm={6}
                lg={couponCodeTypeFromChild === 'Multiple Code' ? 3 : 4}
                sx={{ margin: '10px' }}
              >
                <Card sx={{ height: '100%' }}>
                  <CardContent>
                    <Stack
                      alignItems="flex-start"
                      direction="row"
                      justifyContent="space-between"
                      spacing={3}
                    >
                      <Stack spacing={1}>
                        <Typography color="text.secondary" variant="overline">
                          Users
                        </Typography>
                        <Typography variant="h4">
                          {couponCodeTypeFromChild2}
                        </Typography>
                      </Stack>
                      <Avatar
                        sx={{
                          backgroundColor: 'primary.main',
                          height: 56,
                          width: 56
                        }}
                      >
                        <SvgIcon>
                          <PeopleAltIcon />
                        </SvgIcon>
                      </Avatar>
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            )}
            {couponCodeTypeFromChild === 'Link' && (
              <Grid
                xs={12}
                sm={6}
                lg={couponCodeTypeFromChild === 'Multiple Code' ? 3 : 4}
                sx={{ margin: '10px' }}
              >
                <Card sx={{ height: '100%' }}>
                  <CardContent>
                    <Stack
                      alignItems="flex-start"
                      direction="row"
                      justifyContent="space-between"
                      spacing={3}
                    >
                      <Stack spacing={1}>
                        <Typography color="text.secondary" variant="overline">
                          Users
                        </Typography>
                        <Typography variant="h4">
                          {couponCodeTypeFromChild2}
                        </Typography>
                      </Stack>
                      <Avatar
                        sx={{
                          backgroundColor: 'primary.main',
                          height: 56,
                          width: 56
                        }}
                      >
                        <SvgIcon>
                          <PeopleAltIcon />
                        </SvgIcon>
                      </Avatar>
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            )}

            {couponCodeTypeFromChild === 'Multiple Code' && (
              <Grid
                xs={12}
                sm={6}
                lg={couponCodeTypeFromChild === 'Multiple Code' ? 3 : 4}
                sx={{ margin: '10px' }}
              >
                <Card sx={{ height: '100%' }}>
                  <CardContent>
                    <Stack
                      alignItems="flex-start"
                      direction="row"
                      justifyContent="space-between"
                      spacing={3}
                    >
                      <Stack spacing={1}>
                        <Typography color="text.secondary" variant="overline">
                          Total Code
                        </Typography>
                        <Typography variant="h4">
                          {couponCodeTypeFromChild3}
                        </Typography>
                      </Stack>
                      <Avatar
                        sx={{
                          backgroundColor: avatarColor,
                          height: 56,
                          width: 56
                        }}
                      >
                        <SvgIcon>
                          {couponCodeTypeFromChild === 'Multiple Code' ? (
                            <DiscountIcon />
                          ) : (
                            <PeopleAltIcon />
                          )}
                        </SvgIcon>
                      </Avatar>
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            )}
            {couponCodeTypeFromChild === 'Multiple Code' && (
              <Grid xs={12} sm={6} lg={3} sx={{ margin: '10px' }}>
                <Card sx={{ height: '100%' }}>
                  <CardContent>
                    <Stack
                      alignItems="flex-start"
                      direction="row"
                      justifyContent="space-between"
                      spacing={3}
                    >
                      <Stack spacing={1}>
                        <Typography color="text.secondary" variant="overline">
                          Not Used Code
                        </Typography>
                        <Typography variant="h4">
                          {couponCodeTypeFromChild4}
                        </Typography>
                      </Stack>
                      <Avatar
                        sx={{
                          backgroundColor: 'warning.main',
                          height: 56,
                          width: 56
                        }}
                      >
                        <SvgIcon>
                          <DiscountIcon />
                        </SvgIcon>
                      </Avatar>
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>

      <Container maxWidth="lg">
        <Stack spacing={3}>
          <Stack direction="row" justifyContent="space-between" spacing={4}>
            <Stack spacing={1}>
              <Typography variant="h4">
                {couponCodeTypeFromChild === 'Multiple Code'
                  ? 'Multiple Code'
                  : couponCodeTypeFromChild === 'Single Code'
                  ? 'Single Code'
                  : couponCodeTypeFromChild === 'Link'
                  ? 'Link'
                  : null}
              </Typography>
            </Stack>
            <div>
              {couponCodeTypeFromChild === 'Multiple Code' && (
                <Button
                  onClick={() => setShowForms(!showForms)}
                  startIcon={
                    <SvgIcon fontSize="small">
                      <AddIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                >
                  {showForms ? 'Show Table List' : 'Add'}
                </Button>
              )}
            </div>
          </Stack>
          {showForms ? (
            <MultipleCodeForm />
          ) : (
            <CompanyCoupenCode
              sendCouponCodeType={handleCouponCodeTypeFromChild}
              sendTotalUser={handleCouponCodeTypeFromChild2}
              sendTotalCode={handleCouponCodeTypeFromChild3}
              sendUsedCode={handleCouponCodeTypeFromChild4}
              sx={{ height: '100%' }}
            />
          )}
        </Stack>
      </Container>
    </>
  );
};

export default AddMutipleCode;
