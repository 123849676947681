import { Box, Container, Unstable_Grid2 as Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCompanyData } from '../Store/Sclies/CamapanySlice';
import { TotalUsers } from '../sections/overview/TotalUsers';
import { TotalCompanies } from '../sections/overview/TotalCompanies';
import { OverviewChart } from '../sections/overview/OverviewChart';
import { PaddingUsersTable } from '../sections/overview/PaddingUsersTable';
// import { VerifiedUsers } from '../sections/overview/VerifiedUsers';
// import { PenddingVerification } from '../sections/overview/PenddingVerification';

const OverView = () => {
  const dispatch = useDispatch();
  const [totalLoginUsers, setTotaluser] = useState(0);
  // const [varifiedUsers, setVarifiedUser] = useState([]);
  const [paddingUsers, setPaddingUser] = useState([]);
  const [notAppiledUsers, setNotAppiledUser] = useState([]);
  const [genders, setGenders] = useState([]);

  const { data } = useSelector((state) => state.company);
  const getTotalUserCount = async () => {
    const usersCollection = collection(db, 'Users');
    const usersSnapshot = await getDocs(usersCollection);
    const array = [];
    const appliedArray = [];
    const NotappliedArray = [];
    let i = 0;
    usersSnapshot.forEach((doc) => {
      if (doc.data().Document_varification === 'Approved') {
        array.push(doc.data());
      } else if (doc.data().Document_varification === 'Applied') {
        appliedArray.push({ id: doc.id, ids: i++, data: doc.data() });
      } else if (doc.data().Document_varification === 'Not Applied') {
        NotappliedArray.push(doc.data());
      }
    });
    const totalUsers = usersSnapshot.size;

    const genders = usersSnapshot.docs.map((doc) => doc.data().gender);
    setGenders(genders);
    // setVarifiedUser(array);
    setPaddingUser(appliedArray);
    setTotaluser(totalUsers);
    setNotAppiledUser(NotappliedArray);
  };

  useEffect(() => {
    getTotalUserCount();
    dispatch(fetchCompanyData());
  }, []);

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8
        }}
      >
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid xs={12} sm={6} lg={3}>
              <TotalUsers
                difference={12}
                positive
                sx={{ height: '100%' }}
                value={totalLoginUsers}
              />
            </Grid>
            <Grid xs={12} sm={6} lg={3}>
              <TotalCompanies
                difference={16}
                positive={false}
                sx={{ height: '100%' }}
                value={data?.length}
              />
            </Grid>
            <Grid xs={12} sm={6} lg={3}>
              {/* <VerifiedUsers value={varifiedUsers?.length} /> */}
            </Grid>
            <Grid xs={12} sm={6} lg={3}>
              {/* <PenddingVerification value={paddingUsers?.length} /> */}
            </Grid>
            <Grid xs={12} md={12} lg={6}>
              <OverviewChart
                sx={{ height: '100%' }}
                data={notAppiledUsers?.length}
                paddingUsers={paddingUsers?.length}
                users={totalLoginUsers}
                user={totalLoginUsers}
                genders={genders}
              />
            </Grid>
            {/* 333 */}
            <Grid xs={12} md={12} lg={6}>
              <PaddingUsersTable paddingUsers={paddingUsers} />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default OverView;
