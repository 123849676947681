import React from 'react';

import { useCallback, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Unstable_Grid2 as Grid,
  IconButton,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Stack } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { db, storage } from '../../firebase';
import { doc, updateDoc } from 'firebase/firestore';
import { toast } from 'react-hot-toast';
import { hideLoader, showLoader } from '../../Store/Sclies/loaderSlice';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const CompanyEdit = ({ company, setEditFrom }) => {
  console.log(company);
  const dispatch = useDispatch();
  let downloadURL = '';
  let downloadURL2 = '';
  let downloadURL3 = '';
  let downloadURLGeneralInfo = '';
  const isLoading = useSelector((state) => state.loader);
  const [values, setValues] = useState({
    companyName: company.company_Name ?? '',
    status: company.status ?? '',
    desc: company.desc ?? '',
    howItWorks: company.howItWorks ?? '',
    urlLink: company.urlLink ?? '',
    title: company.title ?? '',
    metaTitle: company.metaTitle ?? '',
    metaDescription: company.metaDescription ?? '',
    howItWorksTitle: company.howItWorksTitle ?? '',
    generalInfoTitle: company.generalInfoTitle ?? '',
    generalInfoDescription: company.generalInfoDescription ?? '',
    altBackgroundImage: company.altBackgroundImage ?? '',
    altHowItWorks: company.altHowItWorks ?? '',
    altGeneralImage: company.altGeneralImage ?? '',
    faq: company.faq ?? [
      { question: '', answer: '' },
      { question: '', answer: '' },
      { question: '', answer: '' },
      { question: '', answer: '' },
      { question: '', answer: '' }
    ]
  });
  console.log(values);
  const [selectedImage, setSelectedImage] = useState('');
  const [selectedImageLogo, setSelectedImageLogo] = useState('');
  const [selectedImageHowItWorks, setSelectedImageHowItWorks] = useState('');
  const [selectedImageGeneralInfo, setSelectedImageGeneralInfo] = useState('');

  const [previewUrl, setPreviewUrl] = useState(company.bgImage);
  const [previewUrlLogo, setPreviewUrlLogo] = useState(company.logo);
  const [previewUrlHowItWorks, setPreviewHowItWorks] = useState(
    company.howItWorksImage
  );
  const [previewGeneralInfo, setPreviewGeneralInfo] = useState(
    company.generalInfoImage
  );

  const handleChange = useCallback((event) => {
    if (event.target.name == 'status') {
      if (event.target != undefined) {
        setValues((prevState) => ({
          ...prevState,
          [event.target.name]: event.target.value
        }));
      }
    } else {
      if (event.target != undefined) {
        setValues((prevState) => ({
          ...prevState,
          [event.target.id]: event.target.value
        }));
      }
    }
  }, []);

  const handleDescEdit = (value) => {
    setValues((prevState) => ({
      ...prevState,
      desc: value
    }));
  };
  const handleHowItWorks = (value) => {
    setValues((prevState) => ({
      ...prevState,
      howItWorks: value
    }));
  };

  const handleGeneralInfo = (value) => {
    setValues((prevState) => ({
      ...prevState,
      generalInfoDescription: value
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    dispatch(showLoader());
    try {
      if (selectedImage) {
        const storageRefBackGround = ref(
          storage,
          `CompanyBgImg/${values.companyName}_${selectedImage.name}`
        );
        await uploadBytes(storageRefBackGround, selectedImage);
        downloadURL = await getDownloadURL(storageRefBackGround);
      } else if (selectedImageLogo) {
        const storageRefLogo = ref(
          storage,
          `CompanyImg/${values.companyName}_${selectedImageLogo.name}`
        );
        await uploadBytes(storageRefLogo, selectedImageLogo);
        downloadURL2 = await getDownloadURL(storageRefLogo);
      } else if (selectedImageHowItWorks) {
        const storageRefLogo = ref(
          storage,
          `CompanyImg/${values.companyName}_${selectedImageHowItWorks.name}`
        );
        await uploadBytes(storageRefLogo, selectedImageHowItWorks);
        downloadURL3 = await getDownloadURL(storageRefLogo);
      } else if (selectedImageGeneralInfo) {
        const storageRefGeneralInfo = ref(
          storage,
          `CompanyImg/${values.companyName}_${selectedImageGeneralInfo.name}`
        );
        await uploadBytes(storageRefGeneralInfo, selectedImageGeneralInfo);
        downloadURLGeneralInfo = await getDownloadURL(storageRefGeneralInfo);
      }

      //add data to company collection
      const documentRef = doc(
        db,
        // 'Admin',
        // 'AdminPanelSubCol',
        'Company',
        values.companyName
      );
      await updateDoc(documentRef, {
        company_Name: values.companyName,

        desc: values.desc,
        urlLink: values.urlLink,
        title: values.title,
        metaTitle: values.metaTitle,
        metaDescription: values.metaDescription,
        howItWorks: values.howItWorks,
        bgImage: downloadURL !== '' ? downloadURL : previewUrl,
        logo: downloadURL2 !== '' ? downloadURL2 : previewUrlLogo,
        howItWorksImage:
          downloadURL3 !== '' ? downloadURL3 : previewUrlHowItWorks,
        howItWorksTitle: values.howItWorksTitle,
        generalInfoTitle: values.generalInfoTitle,
        generalInfoDescription: values.generalInfoDescription,
        generalInfoImage:
          downloadURLGeneralInfo !== ''
            ? downloadURLGeneralInfo
            : previewGeneralInfo,
        altBackgroundImage: values.altBackgroundImage,
        altHowItWorks: values.altHowItWorks,
        altGeneralImage: values.altGeneralImage,
        faq: values.faq,
        timestamp: new Date(),
        status: values.status
      }).then(() => {
        setEditFrom(false);
        dispatch(hideLoader());
        toast.success('Company update Successfully');
      });
    } catch (error) {
      dispatch(hideLoader());
      console.error('Error uploading image:', error);
    }
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);

      const reader = new FileReader();
      reader.onload = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleImageUploadLogo = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImageLogo(file);

      const reader = new FileReader();
      reader.onload = () => {
        setPreviewUrlLogo(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageUploadHowItWorks = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImageHowItWorks(file);

      const reader = new FileReader();
      reader.onload = () => {
        setPreviewHowItWorks(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageUploadGeneralInfo = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImageGeneralInfo(file);

      const reader = new FileReader();
      reader.onload = () => {
        setPreviewGeneralInfo(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFaqChange = (event) => {
    const index = event.target.name.split('')[1];

    setValues((prevState) => {
      const updatedFaq = [...prevState.faq];
      updatedFaq[index - 1].question = event.target.value;
      return {
        ...prevState,
        faq: updatedFaq
      };
    });
  };

  const handleAnswerChange = (value, index) => {
    setValues((prevState) => {
      const updatedFaq = [...prevState.faq];

      updatedFaq[index].answer = value;
      return {
        ...prevState,
        faq: updatedFaq
      };
    });
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <Card>
        <CardHeader title="Company Profile Edit" />
        <CardContent sx={{ pt: 0 }}>
          <Box sx={{ m: -1.5 }}>
            <Grid container spacing={3}>
              {/* <Grid xs={12} md={6}>
                <FormControl variant="filled" fullWidth>
                  <InputLabel id="demo-simple-select-filled-label">
                    Category
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="Category"
                    value={values.Category}
                    onChange={handleChange}
                  >
                    <MenuItem value="">None</MenuItem>
                    {category.map((option, index) => (
                      <MenuItem key={index.toString()} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid> */}
              <Grid xs={12} md={12}>
                <FormControl variant="filled" fullWidth>
                  <InputLabel id="demo-simple-select-filled-label">
                    Company Status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="status"
                    name="status"
                    value={values.status}
                    onChange={handleChange}
                  >
                    <MenuItem value="">none</MenuItem>
                    <MenuItem value="active">Active </MenuItem>
                    <MenuItem value="deactive">Inactive </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={12} md={6}>
                <TextField
                  disabled
                  fullWidth
                  label="Name of the company"
                  name="companyName"
                  id="companyName"
                  onChange={handleChange}
                  required
                  value={values.companyName}
                  style={{ marginBottom: '30px' }}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Link of the company"
                  name="urlLink"
                  id="urlLink"
                  onChange={handleChange}
                  required
                  value={values.urlLink}
                />
              </Grid>

              <Grid xs={12} md={6}>
                <CardHeader title="Meta Info" />

                <TextField
                  fullWidth
                  label="Meta title of the company"
                  name="metaTitle"
                  id="metaTitle"
                  onChange={handleChange}
                  value={values.metaTitle}
                />
              </Grid>
              <Grid xs={12} md={6} alignItems={'flex-end'} display={'flex'}>
                <TextField
                  fullWidth
                  label="Meta description of the company"
                  name="metaDescription"
                  id="metaDescription"
                  onChange={handleChange}
                  value={values.metaDescription}
                  style={{ marginTop: '30px' }}
                />
              </Grid>

              <Grid xs={12} md={6}>
                <CardHeader title="Company Description" />
                <TextField
                  fullWidth
                  label="Company Description Title"
                  name="title"
                  id="title"
                  onChange={handleChange}
                  value={values.title}
                  style={{
                    marginBottom: '30px'
                  }}
                />
                <ReactQuill
                  theme="snow" // Choose a theme - snow is one of the themes provided by Quill
                  value={values.desc}
                  onChange={handleDescEdit}
                  style={{
                    height: '200px',
                    marginBottom: '60px',
                    fontSize: '18px'
                  }}
                />
                <TextField
                  fullWidth
                  label="Alt tag background picture"
                  name="altBackgroundImage"
                  id="altBackgroundImage"
                  onChange={handleChange}
                  value={values.altBackgroundImage}
                />
              </Grid>

              <Grid xs={12} md={6}>
                <Stack
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-evenly"
                >
                  <Stack sx={{ alignItems: 'center', width: '150px' }}>
                    <input
                      onChange={handleImageUploadLogo}
                      accept="image/*"
                      style={{ display: 'none' }}
                      id="raised-button-file"
                      type="file"
                    />
                    <label htmlFor="raised-button-file">
                      <IconButton
                        sx={{ width: '50px', height: '50px' }}
                        size="large"
                        component="span"
                      >
                        <CloudUploadIcon color="primary" />
                      </IconButton>
                    </label>
                    <Typography>Logo</Typography>
                  </Stack>
                </Stack>
                <Stack
                  mt={4}
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="center"
                >
                  {previewUrlLogo && (
                    <Card className="p-[20px]">
                      <img
                        src={previewUrlLogo}
                        alt="Image Preview."
                        className="max-h-[80px] w-full object-contain"
                      />
                    </Card>
                  )}
                </Stack>
                <Stack
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-evenly"
                >
                  <Stack sx={{ alignItems: 'center', width: '150px' }}>
                    <input
                      onChange={handleImageUpload}
                      accept="image/*"
                      style={{ display: 'none' }}
                      id="raised-button"
                      // name="fileInput1"
                      type="file"
                    />
                    <label htmlFor="raised-button">
                      <IconButton
                        sx={{ width: '50px', height: '50px' }}
                        size="large"
                        component="span"
                      >
                        <CloudUploadIcon color="primary" />
                      </IconButton>
                    </label>
                    <Typography>Description image</Typography>
                  </Stack>
                </Stack>
                <Stack
                  mt={4}
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-evenly"
                >
                  {previewUrl && (
                    <Card className="w-full max-w-[600px] h-[400px] lg:h-[390px] sm:h-[280px]">
                      <img
                        src={previewUrl}
                        alt="Image Preview."
                        className="max-h-[400px] h-full w-full object-cover"
                      />
                    </Card>
                  )}
                </Stack>
              </Grid>

              <Grid xs={12} md={6}>
                <CardHeader title="How it works" />
                <TextField
                  fullWidth
                  label="How it works title"
                  name="howItWorksTitle"
                  id="howItWorksTitle"
                  onChange={handleChange}
                  value={values.howItWorksTitle}
                  style={{ marginBottom: '30px' }}
                />
                <ReactQuill
                  theme="snow" // Choose a theme - snow is one of the themes provided by Quill
                  value={values.howItWorks}
                  onChange={handleHowItWorks}
                  style={{
                    height: '200px',
                    marginBottom: '60px',
                    fontSize: '18px'
                  }}
                />
                <TextField
                  fullWidth
                  label="Alt tag how it works picture"
                  name="altHowItWorks"
                  id="altHowItWorks"
                  onChange={handleChange}
                  value={values.altHowItWorks}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <Stack
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-evenly"
                >
                  <Stack sx={{ alignItems: 'center', width: '150px' }}>
                    <input
                      onChange={handleImageUploadHowItWorks}
                      accept="image/*"
                      style={{ display: 'none' }}
                      id="raised-button-how"
                      // name="fileInput1"
                      type="file"
                    />
                    <label htmlFor="raised-button-how">
                      <IconButton
                        sx={{ width: '50px', height: '50px' }}
                        size="large"
                        component="span"
                      >
                        <CloudUploadIcon color="primary" />
                      </IconButton>
                    </label>
                    <p>How it works image</p>
                  </Stack>
                </Stack>
                <Stack
                  mt={4}
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-evenly"
                >
                  {previewUrlHowItWorks && (
                    <Card className="w-full max-w-[600px] h-[400px] lg:h-[390px] sm:h-[280px]">
                      <img
                        src={previewUrlHowItWorks}
                        alt="Image Preview."
                        className="max-h-[400px] h-full w-full object-cover"
                      />
                    </Card>
                  )}
                </Stack>
              </Grid>

              <Grid xs={12} md={6}>
                <CardHeader title="  General Info" />
                <TextField
                  fullWidth
                  label="General Info title"
                  name="generalInfoTitle"
                  id="generalInfoTitle"
                  onChange={handleChange}
                  value={values.generalInfoTitle}
                  style={{ marginBottom: '30px' }}
                />
                <ReactQuill
                  theme="snow" // Choose a theme - snow is one of the themes provided by Quill
                  value={values.generalInfoDescription}
                  onChange={handleGeneralInfo}
                  style={{ height: '200px', marginBottom: '60px' }}
                />
                <TextField
                  fullWidth
                  label="Alt tag general info picture"
                  name="altGeneralImage"
                  id="altGeneralImage"
                  onChange={handleChange}
                  value={values.altGeneralImage}
                />
              </Grid>

              <Grid xs={12} md={6}>
                <Stack
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-evenly"
                >
                  <Stack sx={{ alignItems: 'center', width: '150px' }}>
                    <input
                      onChange={handleImageUploadGeneralInfo}
                      accept="image/*"
                      style={{ display: 'none' }}
                      id="raised-button-general"
                      // name="fileInput1"
                      type="file"
                    />
                    <label htmlFor="raised-button-general">
                      <IconButton
                        sx={{ width: '50px', height: '50px' }}
                        size="large"
                        component="span"
                      >
                        <CloudUploadIcon color="primary" />
                      </IconButton>
                    </label>
                    <p>General info image</p>
                  </Stack>
                </Stack>
                <Stack
                  mt={4}
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-evenly"
                >
                  {previewGeneralInfo && (
                    <Card className="w-full max-w-[600px] h-[400px] lg:h-[390px] sm:h-[280px]">
                      <img
                        src={previewGeneralInfo}
                        alt="Image Preview."
                        className="max-h-[400px] h-full w-full object-cover"
                      />
                    </Card>
                  )}
                </Stack>
              </Grid>
              <Grid xs={12} md={12}>
                <CardHeader title="FAQs" />

                {Array.from({ length: 5 }, (_, index) => (
                  <div key={index}>
                    <TextField
                      fullWidth
                      label={`Question ${index + 1}`}
                      name={`q${index + 1}`}
                      onChange={handleFaqChange}
                      value={values.faq[index].question}
                      style={{ marginBottom: '30px', marginTop: '30px' }}
                    />
                    <ReactQuill
                      theme="snow" // Choose a theme - snow is one of the themes provided by Quill
                      value={values.faq[index].answer}
                      onChange={(value) => handleAnswerChange(value, index)}
                      style={{ height: '100px', marginBottom: '60px' }}
                    />
                  </div>
                ))}
              </Grid>
            </Grid>
          </Box>
        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button variant="contained" type="submit" disabled={isLoading}>
            Save
          </Button>
        </CardActions>
      </Card>
    </form>
  );
};
export default CompanyEdit;
