import React from 'react';
import { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Unstable_Grid2 as Grid,
  IconButton,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Stack } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCompanyData } from '../../Store/Sclies/CamapanySlice';
import { collection, doc, getDocs, updateDoc } from 'firebase/firestore';
import { db, storage } from '../../firebase';
import { toast } from 'react-hot-toast';
import { hideLoader, showLoader } from '../../Store/Sclies/loaderSlice';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { updateArray } from '../../Store/Sclies/CatagorySlice';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// Age Verication
// const coupon_Type = [
//   {
//     value: 'Proactive',
//     label: 'Proactive'
//   },
//   {
//     value: 'Retroactive',
//     label: 'Retroactive'
//   },
//   {
//     value: 'Open',
//     label: 'Open'
//   }
// ];
const coupon_Type = [
  {
    value: 'Required',
    label: 'Required'
  },
  {
    value: 'Not Required',
    label: 'Not Required'
  }
];

const CouponEdit = ({ couponData }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loader);
  const [selectedImage, setSelectedImage] = useState('');
  const [selectedLogoImage, setSelectedLogoImage] = useState('');
  const [previewUrl, setPreviewUrl] = useState(couponData.data.CouponBgImage);
  const [previewLogoUrl, setPreviewLogoUrl] = useState(
    couponData.data.CouponLogo
  );

  const category = useSelector((state) => state.catagory.items);

  const [coupons, setCoupons] = useState([]);

  const fetchData2 = async () => {
    dispatch(showLoader());
    const ref = collection(db, 'HomepageCoupon');
    const querySnapshot = await getDocs(ref);

    querySnapshot.docs.map(async (doc) => {
      setCoupons(doc.id);
    });
    dispatch(hideLoader());
  };

  useEffect(() => {
    fetchData2();
  }, []);

  const fetchData = async () => {
    try {
      const arr = [];
      const ref = collection(db, 'Admin/AdminPanelSubCol/Category');
      const querySnapshot = await getDocs(ref);
      let i = 1;
      querySnapshot.forEach((doc) => {
        arr.push({ id: i, Name: doc.data().name });
        i++;
      });
      dispatch(updateArray(arr));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    fetchData();
    dispatch(fetchCompanyData());
  }, [dispatch]);

  const [values, setValues] = useState({
    CompanyType: couponData?.data?.CompanyType ?? '',
    BranchCard: couponData?.data?.BranchCard ?? '',
    Termsandconditions: couponData?.data.Termsandconditions ?? '',
    discount: couponData?.data.discount ?? '',
    group: couponData?.data.group ?? '',
    year: couponData?.data.year ?? '',
    status: couponData?.data.status ?? '',
    Category: couponData?.data.Category ?? '',
    urlLink: couponData?.data.urlLink ?? '',
    work: couponData?.data.work ?? ''
  });

  const handleChange = useCallback((event) => {
    setValues((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value
    }));
  }, []);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);

      const reader = new FileReader();
      reader.onload = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleLogoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedLogoImage(file);

      const reader = new FileReader();
      reader.onload = () => {
        setPreviewLogoUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    dispatch(showLoader()); // Display the loader
    try {
      if (selectedImage) {
        const storageRef = ref(storage, couponData.data.CouponBgImage);
        await uploadBytes(storageRef, selectedImage);
      }
      let logoLink = '';
      if (selectedLogoImage) {
        if (couponData.data.CouponLogo == '') {
          const storageRef = ref(
            storage,
            `CouponLogo/${selectedLogoImage.name}`
          );
          await uploadBytes(storageRef, selectedLogoImage);
          logoLink = await getDownloadURL(storageRef);
        } else {
          const storageRef = ref(storage, couponData.data.CouponLogo);
          await uploadBytes(storageRef, selectedLogoImage);
        }
      }
      const documentRef = doc(db, `Coupon/${couponData.id}`);
      const {
        CompanyType,
        BranchCard,
        discount,
        group,
        status,
        Category,
        urlLink,
        work,
        Termsandconditions
      } = values;
      await updateDoc(documentRef, {
        CompanyType,
        BranchCard,
        Termsandconditions,
        CouponLogo: logoLink != '' ? logoLink : couponData.data.CouponLogo,
        discount,
        group,
        work,
        status,
        Category,
        urlLink
      });

      const matchUid = coupons.includes(couponData.id);

      if (matchUid) {
        const HomePage = doc(db, 'HomepageCoupon', couponData.id);
        await updateDoc(HomePage, {
          CompanyType,
          BranchCard,
          Termsandconditions,
          discount,
          group,
          work,
          status,
          Category,
          urlLink
        });
      }
      dispatch(hideLoader());
      toast.success('Coupon edit Successfully');
    } catch (error) {
      dispatch(hideLoader());
      toast.error('Coupon not added ');
      console.error('Error uploading image:', error);
    }
  };

  const handleHowItWorks = (value) => {
    setValues((prevState) => ({
      ...prevState,
      work: value
    }));
  };
  const handleTermsAndConditions = (value) => {
    setValues((prevState) => ({
      ...prevState,
      Termsandconditions: value
    }));
  };

  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <CardHeader title="Coupon Edit" />
        <CardContent sx={{ pt: 0 }}>
          <Box sx={{ m: -1.5 }}>
            <Grid container spacing={3}>
              <Grid xs={12} md={6}>
                <FormControl variant="filled" fullWidth>
                  <InputLabel id="demo-simple-select-filled-label">
                    Coupon Status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="status"
                    value={values.status}
                    onChange={handleChange}
                  >
                    <MenuItem value="">none</MenuItem>
                    <MenuItem value="active">Active </MenuItem>
                    <MenuItem value="deactive">Inactive </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={12} md={6}>
                <FormControl variant="filled" fullWidth>
                  <InputLabel id="demo-simple-select-filled-label">
                    Category
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="Category"
                    value={values.Category}
                    onChange={handleChange}
                  >
                    <MenuItem value="">None</MenuItem>
                    {category.map((option, index) => (
                      <MenuItem key={index.toString()} value={option.Name}>
                        {option.Name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={12} md={6}>
                <FormControl variant="filled" fullWidth>
                  <InputLabel id="demo-simple-select-filled-label">
                    Age Verification
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="CompanyType"
                    value={values.CompanyType}
                    onChange={handleChange}
                  >
                    {coupon_Type.map((option, index) => (
                      <MenuItem key={index.toString()} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Description (Small and Mini Coupon)"
                  name="BranchCard"
                  onChange={handleChange}
                  required
                  value={values.BranchCard}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Discount in %"
                  name="discount"
                  onChange={handleChange}
                  required
                  value={values.discount}
                />
              </Grid>
              <Grid xs={12} md={6}></Grid>
              <Grid xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Description (Big Coupon)"
                  name="group"
                  onChange={handleChange}
                  type="text"
                  value={values.group}
                />
              </Grid>{' '}
              <Grid xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Link of the company"
                  name="urlLink"
                  onChange={handleChange}
                  required
                  value={values.urlLink}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <Typography style={{ marginBottom: '10px', fontSize: '20px' }}>
                  How it works
                </Typography>
                <ReactQuill
                  theme="snow" // Choose a theme - snow is one of the themes provided by Quill
                  value={values.work}
                  onChange={handleHowItWorks}
                  style={{ height: '200px', marginBottom: '30px' }}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <Typography style={{ marginBottom: '10px', fontSize: '20px' }}>
                  Terms and Conditions
                </Typography>
                <ReactQuill
                  theme="snow" // Choose a theme - snow is one of the themes provided by Quill
                  value={values.Termsandconditions}
                  onChange={handleTermsAndConditions}
                  style={{ height: '200px', marginBottom: '30px' }}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <Stack
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="space-evenly"
                >
                  <Stack sx={{ alignItems: 'center', width: '150px' }}>
                    <input
                      onChange={handleImageUpload}
                      accept="image/*"
                      style={{ display: 'none' }}
                      id="raised-button"
                      // name="fileInput1"
                      type="file"
                    />
                    <IconButton sx={{ width: '50px', height: '50px' }}>
                      <label htmlFor="raised-button">
                        <CloudUploadIcon color="primary" />
                      </label>
                    </IconButton>
                    <Typography>Background Image</Typography>
                  </Stack>
                  {previewUrl && (
                    <Card
                      sx={{
                        alignItems: 'center'
                      }}
                    >
                      <CardContent>
                        <img
                          src={previewUrl}
                          alt="Image Preview."
                          style={{ maxWidth: '300px' }}
                        />
                      </CardContent>
                    </Card>
                  )}
                </Stack>
              </Grid>
              <Grid xs={12} md={6}>
                <Stack
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="space-evenly"
                >
                  <Stack sx={{ alignItems: 'center', width: '150px' }}>
                    <input
                      onChange={handleLogoUpload}
                      accept="image/*"
                      style={{ display: 'none' }}
                      id="raised-button-logo-image"
                      // name="fileInput1"
                      type="file"
                    />
                    <IconButton sx={{ width: '50px', height: '50px' }}>
                      <label htmlFor="raised-button-logo-image">
                        <CloudUploadIcon color="primary" />
                      </label>
                    </IconButton>
                    <Typography>Company Logo</Typography>
                  </Stack>
                  {previewLogoUrl && (
                    <Card
                      sx={{
                        alignItems: 'center'
                      }}
                    >
                      <CardContent>
                        <img
                          src={previewLogoUrl}
                          alt="Image Preview."
                          style={{ maxWidth: '300px' }}
                        />
                      </CardContent>
                    </Card>
                  )}
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button variant="contained" type="submit" disabled={isLoading}>
            Save
          </Button>
        </CardActions>
      </Card>
    </form>
  );
};
export default CouponEdit;
