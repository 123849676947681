import { useCallback, useState } from 'react';
import React from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  TextField,
  Unstable_Grid2 as Grid
} from '@mui/material';
import { toast } from 'react-hot-toast';
import { addDoc, collection, getDocs, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { useDispatch, useSelector } from 'react-redux';
import { hideLoader, showLoader } from '../../Store/Sclies/loaderSlice';
const CategoryForm = ({ fetchData }) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState('');

  const isLoading = useSelector((state) => state.loader);

  const handleChange = useCallback((event) => {
    setValues(event.target.value);
  }, []);
  const Add_Category_Firebase = async () => {
    dispatch(showLoader()); //
    try {
      const documentRef = collection(
        db,
        'Admin',
        'AdminPanelSubCol',
        'Category'
      );

      // const webCategory = collection(
      //   db,
      //   'Admin',
      //   'AdminPanelSubCol',
      //   'webCategory'
      // );

      const querySnapshot = await getDocs(documentRef);
      let id = querySnapshot.size + 1;
      const newDocumentRef = await addDoc(documentRef, {
        name: values,
        timestamp: new Date(),
        RowId: id
      });
      // await addDoc(webCategory, {
      //   name: values,
      //   timestamp: new Date(),
      //   RowId: id
      // });

      // await setDoc(
      //   doc(db, 'Admin', 'AdminPanelSubCol', 'webCategory', newDocumentId),
      //   {
      //     name: values,
      //     timestamp: new Date(),
      //     RowId: id
      //   }
      // );

      const newDocumentId = newDocumentRef.id;

      await updateDoc(newDocumentRef, { ids: newDocumentId });
      id++;

      fetchData();
      toast.success('Category added Successfully');
      setValues('');
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      toast.error('Category not added!');
      console.error('Error adding document to Firestore:', error);
    }
  };
  const handleSubmit = (event) => {
    Add_Category_Firebase();
    event.preventDefault();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <CardContent sx={{ p: 6 }}>
          <Box sx={{ m: -1.5 }}>
            <Grid container spacing={3}>
              <Grid xs={12} md={12}>
                <TextField
                  fullWidth
                  helperText="Please specify the Category"
                  label="Category Name"
                  name="CategoryName"
                  onChange={handleChange}
                  required
                  value={values}
                />
              </Grid>
            </Grid>
          </Box>
        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button variant="contained" type="submit" disabled={isLoading}>
            Add Category
          </Button>
        </CardActions>
      </Card>
    </form>
  );
};
export default CategoryForm;
