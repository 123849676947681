import { Navigate } from 'react-router-dom';
import React from 'react';

const isAuthenticated = () => {
  const login = JSON.parse(localStorage.getItem('User'));

  return login;
};

const ProtectRoute = ({ element }) =>
  isAuthenticated() ? <Navigate to="/" replace /> : element;

export default ProtectRoute;
