import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import { Box, Button, DialogActions, Stack } from '@mui/material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  }
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired
};

export default function AlertDialog({
  open,
  setOpen,
  image,
  rejectDocument,
  AppeoveDocument,
  row
}) {
  const handleClose = () => {
    setOpen(false);
  };
  const currentYear = new Date().getFullYear();
  const age = currentYear - row.birthdate;

  return (
    <div>
      <BootstrapDialog
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography
              noWrap
              variant="body2"
              sx={{ color: 'primary', fontWeight: 600, marginLeft: 2 }}
            >
              {row?.email}
            </Typography>
            <Typography
              noWrap
              variant="body2"
              sx={{ color: 'gray', fontWeight: 400, marginLeft: 2 }}
            >
              {row?.name}
            </Typography>
            <Typography
              noWrap
              variant="body2"
              sx={{ color: 'gray', fontWeight: 400, marginLeft: 2 }}
            >
              Age {age}
            </Typography>
          </Box>
        </BootstrapDialogTitle>
        <DialogContent>
          <Stack alignItems="center" mb={4}>
            <Box component="img" src={image} width="100%" height={300}></Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Stack direction="row" spacing={3} mr={1} mb={1}>
              <Button
                color="success"
                variant="contained"
                onClick={AppeoveDocument}
              >
                Approve
              </Button>
              <Button onClick={rejectDocument} color="error" variant="outlined">
                Reject
              </Button>
            </Stack>
          </Box>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
