import React, { useEffect } from 'react';
import {
  Box,
  Card,
  CardHeader,
  // Chip,
  IconButton,
  Typography
} from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import { DataGrid } from '@mui/x-data-grid';
import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  collection,
  doc,
  onSnapshot,
  query,
  updateDoc,
  where
} from 'firebase/firestore';
import { db } from '../../firebase';
import { hideLoader, showLoader } from '../../Store/Sclies/loaderSlice';
import { toast } from 'react-hot-toast';
import AlertDialog from '../../components/Model/AlertDialog';
const PaddingUsersTable = () => {
  const dispatch = useDispatch();
  const [isopen, setisOpen] = useState(false);
  const [image, setImage] = useState('');
  const [row, setRow] = useState([]);
  const [pageSize, setPageSize] = useState(7);
  const [rowId, setRowId] = useState([]);
  const isLoading = useSelector((state) => state.loader);

  useEffect(() => {
    dispatch(showLoader());
    const q = query(
      collection(db, 'Users'),
      where('Document_varification', '==', 'Applied')
    );
    let i = 1;
    const unsubscribe = onSnapshot(q, (snapshot) => {
      i = 1;
      const documents = snapshot.docs.map((doc) => ({
        id: i++,
        ids: doc.id,
        ...doc.data()
      }));

      documents.reverse();

      setRow(documents);
      dispatch(hideLoader());
    });

    return () => {
      unsubscribe();
    };
  }, []);
  const AppeoveDocument = async () => {
    const User = JSON.parse(localStorage.getItem('User'));

    const documentRef = doc(db, 'Users', rowId?.ids);

    try {
      await updateDoc(documentRef, {
        Document_varification: 'Approved',
        CuratorEmail: User.email
      });
      setisOpen(false);
      toast.success('Status updated successfully!');
    } catch (error) {
      console.error('Error updating document:', error);
    }
  };
  const rejectDocument = async () => {
    const User = JSON.parse(localStorage.getItem('User'));

    const documentRef = doc(db, 'Users', rowId?.ids);

    try {
      await updateDoc(documentRef, {
        Document_varification: 'Rejected',
        CuratorEmail: User.email
      });
      setisOpen(false);
      toast.success('Status updated successfully!');
    } catch (error) {
      console.error('Error updating document:', error);
    }
  };
  const columns = [
    {
      flex: 0.05,
      minWidth: 50,

      field: 'id',
      headerName: 'id',
      renderCell: (params) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                noWrap
                variant="body2"
                sx={{ color: 'primary', fontWeight: 600, marginLeft: 2 }}
              >
                {params.rowNode.id}
              </Typography>
            </Box>
          </Box>
        );
      }
    },
    {
      flex: 0.2,
      minWidth: 200,
      field: 'name',
      headerName: 'Name',
      renderCell: (params) => {
        const { row } = params;

        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                noWrap
                variant="body2"
                sx={{ color: 'primary', fontWeight: 600, marginLeft: 2 }}
              >
                {row?.email}
              </Typography>
              <Typography
                noWrap
                variant="body2"
                sx={{ color: 'gray', fontWeight: 400, marginLeft: 2 }}
              >
                {row?.name}
              </Typography>
            </Box>
          </Box>
        );
      }
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: 'Age',
      headerName: 'Age',
      renderCell: (params) => {
        const { row } = params;
        const currentYear = new Date().getFullYear();
        const birthdate = row?.birthdate;
        const year = parseInt(birthdate.split('/')[2], 10);

        const age = currentYear - year;

        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              {' '}
              <Typography
                noWrap
                variant="body2"
                sx={{ color: 'primary', fontWeight: 600, marginLeft: 2 }}
              >
                {age}
              </Typography>
            </Box>
          </Box>
        );
      }
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: 'Image',
      headerName: 'Documents',
      renderCell: (params) => {
        const { row } = params;

        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                ml: 3
              }}
            >
              <IconButton
                aria-label="delete"
                onClick={() => {
                  setImage(row.Document_url);
                  setRowId(row);
                  setisOpen(true);
                }}
              >
                <ImageIcon />
              </IconButton>
            </Box>
          </Box>
        );
      }
    }

    // Age Verification
    // {
    //   flex: 0.1,
    //   minWidth: 100,
    //   field: 'Status',
    //   headerName: 'Status',
    //   renderCell: (params) => {
    //     const { row } = params;
    //     return (
    //       <Box sx={{ display: 'flex', alignItems: 'center' }}>
    //         <Box
    //           sx={{
    //             display: 'flex',
    //             alignItems: 'center',
    //             justifyContent: 'center',
    //             ml: 1
    //           }}
    //         >
    //           <Chip
    //             label={row.Document_varification}
    //             color={
    //               row.Document_varification === 'Approved' ? 'success' : 'error'
    //             }
    //           />
    //         </Box>
    //       </Box>
    //     );
    //   }
    // }
  ];

  return (
    <>
      <Card>
        <CardHeader title="PENDING VERIFICATION" />
        <DataGrid
          loading={isLoading}
          rows={row}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[7, 10, 25, 50]}
          disableRowSelectionOnClick
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          sx={{
            height: '500px',
            borderRadius: 2,
            borderTop: 1,
            boxShadow: 2,
            border: 2,
            borderColor: 'primary.light',
            '& .MuiDataGrid-cell:hover': {
              color: 'primary.main'
            },
            '& .MuiDataGrid-cell': {},
            '& .MuiDataGrid-row': {
              borderBottom: '1px solid Lightgray'
            },
            '& .MuiDataGrid-columnHeaders': {
              borderBottom: '1px solid Lightgray',
              bgcolor: 'lightgray',
              paddingLeft: 2
            }
          }}
        />
      </Card>
      <AlertDialog
        row={rowId}
        open={isopen}
        setOpen={setisOpen}
        image={image}
        AppeoveDocument={AppeoveDocument}
        rejectDocument={rejectDocument}
      />
    </>
  );
};
export default PaddingUsersTable;
