import { useCallback, useState } from 'react';
import React from 'react';

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  TextField,
  Unstable_Grid2 as Grid
} from '@mui/material';
import { toast } from 'react-hot-toast';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../../firebase';
import { useDispatch, useSelector } from 'react-redux';
import { hideLoader, showLoader } from '../../Store/Sclies/loaderSlice';

const CuratorForm = () => {
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    email: '',
    password: ''
  });

  const isLoading = useSelector((state) => state.loader);

  const handleChange = useCallback((event) => {
    setValues((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value
    }));
  }, []);
  const Add_CuratorFrom_Firebase = async () => {
    dispatch(showLoader()); //
    try {
      const documentRef = collection(db, 'AdminPanelUser');
      await addDoc(documentRef, {
        email: values.email,
        password: values.password,
        role: 'curator'
      }).then(() => {
        toast.success('Add Curator Successfully');
      });
      setValues({
        email: '',
        password: ''
      } );
      dispatch(hideLoader());
    } catch (error) {
      dispatch(hideLoader());
      toast.error('Curator not  added!');
      console.error('Error updating array in Firestore:', error);
    }
  };
  const handleSubmit = (event) => {
    Add_CuratorFrom_Firebase();
    event.preventDefault();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <CardContent sx={{ p: 6 }}>
          <Box sx={{ m: -1.5 }}>
            <Grid container spacing={3}>
              <Grid xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  onChange={handleChange}
                  required
                  value={values.CompanyType}
                ></TextField>
              </Grid>
              <Grid xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Password"
                  name="password"
                  onChange={handleChange}
                  required
                  value={values.urlLink}
                />
              </Grid>
            </Grid>
          </Box>
        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button variant="contained" type="submit" disabled={isLoading}>
            Add Curator
          </Button>
        </CardActions>
      </Card>
    </form>
  );
};
export default CuratorForm;
