import React from 'react';
import { useCallback } from 'react';

import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  MenuItem,
  MenuList,
  Popover,
  Typography
} from '@mui/material';

import { useNavigate } from 'react-router-dom';

const AccountPopover = (props) => {
  const { onClose, open } = props;
  const navigate = useNavigate();
  const User = JSON.parse(localStorage.getItem('User'));
  const handleSignOut = useCallback(() => {
    onClose?.();
    localStorage.clear();
    navigate('login');
  }, []);

  return (
    <Popover
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: 300 } }}
    >
      <Box
        sx={{
          py: 1.5,
          px: 2
        }}
      >
        <Typography variant="overline">Account</Typography>
        <Typography color="text.secondary" variant="body2">
          {User.email}
        </Typography>
      </Box>
      <Divider />
      <MenuList
        disablePadding
        dense
        sx={{
          p: '8px',
          '& > *': {
            borderRadius: 1
          }
        }}
      >
        <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
      </MenuList>
    </Popover>
  );
};

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
};
export default AccountPopover;
