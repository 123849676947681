/* eslint-disable react/react-in-jsx-scope */
import { Box, Container, Stack, Typography } from '@mui/material';
import CuratorTable from '../sections/Qater/Qater-table';

const Curator = () => (
  <>
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8
      }}
    >
      <Container maxWidth="lg">
        <Stack spacing={3}>
          <Typography variant="h4">Age Verification</Typography>
          <CuratorTable />
        </Stack>
      </Container>
    </Box>
  </>
);

export default Curator;
