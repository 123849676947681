import { useEffect, useState } from 'react';
import React from 'react';

import {
  Box,
  Button,
  Container,
  Stack,
  SvgIcon,
  Typography
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { collection, getDocs } from 'firebase/firestore';

import CategoryForm from '../sections/Category/CategoryForm';
import ListOutCatagary from '../sections/Category/ListOutCatagary';
import { db } from '../firebase';
import { useDispatch } from 'react-redux';
import { updateArray } from '../Store/Sclies/CatagorySlice';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const Category = () => {
  const dispatch = useDispatch();

  const [showForms, setShowForms] = useState(false);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const arr = [];
      const ref = collection(db, 'Admin/AdminPanelSubCol/Category');
      const querySnapshot = await getDocs(ref);
      let i = 1;
      querySnapshot.forEach((doc) => {
        arr.push({ id: i, Name: doc.data().name });
        i++;
      });
      dispatch(updateArray(arr));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Typography variant="h4">Category</Typography>
              </Stack>
              <div>
                <Button
                  onClick={() => setShowForms(!showForms)}
                  startIcon={
                    <SvgIcon fontSize="small">
                      <AddIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                >
                  {showForms ? 'Show Category List' : 'Add'}
                </Button>
              </div>
            </Stack>
            {showForms ? (
              <CategoryForm fetchData={fetchData} />
            ) : (
              <DndProvider backend={HTML5Backend}>
                <ListOutCatagary sx={{ height: '100%' }} />
              </DndProvider>
            )}
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export default Category;
