import React, { useEffect } from 'react';

import {
  Box,
  Container,
  Stack,
  Typography,
  Unstable_Grid2 as Grid
} from '@mui/material';
import CuoponForm from '../sections/OfferCuopon/CuoponForm';
import { collection, getDocs } from 'firebase/firestore';
import { updateArray } from '../Store/Sclies/CatagorySlice';
import { db } from '../firebase';
import { useDispatch } from 'react-redux';
import { fetchCompanyData } from '../Store/Sclies/CamapanySlice';

const OfferCoupon = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCompanyData());
    fetchData();
  }, [dispatch]);
  const fetchData = async () => {
    try {
      const arr = [];
      const ref = collection(db, 'Admin/AdminPanelSubCol/Category');
      const querySnapshot = await getDocs(ref);
      let i = 1;
      querySnapshot.forEach((doc) => {
        arr.push({ id: i, Name: doc.data().name });
        i++;
      });
      dispatch(updateArray(arr));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8
        }}
      >
        <Container maxWidth="lg">
          <Stack spacing={3}>
            <div>
              <Typography variant="h4">Add Coupons</Typography>
            </div>
            <div>
              <Grid container spacing={3}>
                <Grid xs={12} md={12}>
                  <CuoponForm />
                </Grid>
              </Grid>
            </div>
          </Stack>
        </Container>
      </Box>
    </>
  );
};
export default OfferCoupon;
