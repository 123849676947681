import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  items: []
};

const CatagorySlice = createSlice({
  name: 'catagory',
  initialState,
  reducers: {
    updateArray: (state, action) => {
      state.items = action.payload;
    }
  }
});

export const { updateArray } = CatagorySlice.actions;
export default CatagorySlice.reducer;
