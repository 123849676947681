import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Typography } from '@mui/material';
import {
  collection,
  deleteDoc,
  doc,
  // doc,
  getDocs,
  query,
  updateDoc,
  // updateDoc,
  where
} from 'firebase/firestore';
import { db } from '../../firebase';
import { updateArray } from '../../Store/Sclies/CatagorySlice';
import { useDrag, useDrop } from 'react-dnd';

function ListOutCatagary() {
  const [pageSize, setPageSize] = useState(7);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loader);
  // const Category = useSelector((state) => state.catagory.items);
  const [arrList, setArrList] = useState([]);
  const fetchData = async () => {
    try {
      const arr = [];
      const ref = collection(db, 'Admin/AdminPanelSubCol/Category');
      const querySnapshot = await getDocs(ref);
      let i = 1;
      querySnapshot.forEach((doc) => {
        arr.push({
          id: i,
          ids: doc.id,
          RowId: doc.data().RowId,
          Name: doc.data().name
        });
        i++;
      });
      arr.sort((a, b) => a.RowId - b.RowId);
      setArrList(arr);
      dispatch(updateArray(arr));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      flex: 0.05,
      field: 'id',
      headerName: 'Order',
      renderCell: (params) => {
        const { row } = params;
        let id = row?.RowId;
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                noWrap
                variant="body2"
                sx={{ color: 'primary', fontWeight: 600 }}
              >
                {id}
              </Typography>
            </Box>
          </Box>
        );
      }
    },
    {
      flex: 0.5,
      minWidth: 290,
      field: 'name',
      headerName: 'Name',
      renderCell: (params) => {
        const { row } = params;
        const [, drag] = useDrag({
          type: 'TABLE_ROW',
          item: {
            id: row.id,
            RowId: row.RowId
          }
        });

        const [, drop] = useDrop({
          accept: 'TABLE_ROW',
          drop: async (droppedItem) => {
            if (droppedItem.id !== row.id) {
              const updatedRows = arrList.map((r) => ({ ...r }));

              const draggedRowIndex = updatedRows.findIndex(
                (r) => r.id === droppedItem.id
              );
              const targetRowIndex = updatedRows.findIndex(
                (r) => r.id === row.id
              );

              const [draggedRow] = updatedRows.splice(draggedRowIndex, 1);
              updatedRows.splice(targetRowIndex, 0, draggedRow);

              updatedRows.forEach((r, index) => {
                r.RowId = index + 1;
              });

              setArrList(updatedRows);

              for (const item of updatedRows) {
                const docRef = doc(
                  db,
                  'Admin/AdminPanelSubCol/Category',
                  item.ids
                );

                await updateDoc(docRef, {
                  name: item.Name,
                  RowId: item.RowId
                });
              }
            }
          }
        });

        return (
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'move'
              }}
              ref={(node) => drag(drop(node))}
              key={row.RowId}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <div>
                  <Typography
                    noWrap
                    variant="body2"
                    sx={{
                      color: 'primary',
                      fontWeight: 600,
                      marginLeft: 2
                    }}
                  >
                    {row.Name}
                  </Typography>
                </div>
              </Box>
            </Box>
          </>
        );
      }
    },
    {
      flex: 0.1,
      minWidth: 20,
      field: 'delett',
      headerName: 'Delete',
      renderCell: (params) => {
        const { row } = params;
        const deleteDocuments = async () => {
          try {
            const collectionRef = collection(
              db,
              'Admin',
              'AdminPanelSubCol',
              'Category'
            );

            // Create a query with the 'where' condition
            const q = query(collectionRef, where('name', '==', row?.Name));

            const querySnapshot = await getDocs(q);

            // Iterate over the matching documents and delete each one
            querySnapshot.forEach(async (doc) => {
              await deleteDoc(doc.ref);
            });
            fetchData();
          } catch (error) {
            console.error('Error deleting documents: ', error);
          }
        };

        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <IconButton
                aria-label="delete"
                onClick={deleteDocuments}
                sx={{
                  ':hover': {
                    bgcolor: 'red',
                    color: 'white'
                  }
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          </Box>
        );
      }
    }
  ];
  return (
    <Box sx={{ height: 400, width: '100%' }}>
      <DataGrid
        loading={isLoading}
        rows={arrList}
        columns={columns}
        pageSize={pageSize}
        rowsPerPageOptions={[5, 10, 25, 50]}
        disableRowSelectionOnClick
        rowReordering
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        sx={{
          borderRadius: 2,
          borderTop: 1,
          boxShadow: 2,
          border: 2,
          height: '500px',
          borderColor: 'primary.light',
          '& .MuiDataGrid-cell:hover': {
            color: 'primary.main'
          },
          '& .MuiDataGrid-cell': {
            paddingLeft: 3
          },
          '& .MuiDataGrid-row': {
            borderBottom: '1px solid Lightgray'
          },
          '& .MuiDataGrid-columnHeaders': {
            borderBottom: '1px solid Lightgray',
            bgcolor: 'lightgray',
            paddingLeft: 2
          }
        }}
      />
    </Box>
  );
}

export default ListOutCatagary;
