import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Stack,
  SvgIcon,
  Typography,
  Unstable_Grid2 as Grid
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CompanyCard from '../sections/companies/company-card';
import CompaniesAddForm from '../sections/companies/CompaniesAddForm';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from '../Store/Sclies/loaderSlice';
import CompanyEdit from '../sections/Edit/CompanyEdit';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const Company = () => {
  const dispatch = useDispatch();
  const [companyList, setCompanyList] = useState([]);
  const [editFrom, setEditFrom] = useState(false);
  const [editCompanyData, setEditCompanyData] = useState([]);

  useEffect(() => {
    dispatch(showLoader());
    // const ref = collection(db, 'Admin/AdminPanelSubCol/Company');
    const ref = collection(db, 'Company');
    const unsubscribe = onSnapshot(ref, (snapshot) => {
      const documents = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }));
      setCompanyList(
        documents.sort((a, b) =>
          a.company_Name
            .toLowerCase()
            .localeCompare(b.company_Name.toLowerCase())
        )
      );

      dispatch(hideLoader());
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const [showForms, setShowForms] = useState(false);
  const showAddButton = !showForms && !editFrom;

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Typography variant="h4">Companies</Typography>
              </Stack>
              <div>
                {showForms ? (
                  <Button
                    onClick={() => {
                      setShowForms(!showForms);
                      setEditFrom(false);
                    }}
                    startIcon={
                      <SvgIcon fontSize="small">
                        <AddIcon />
                      </SvgIcon>
                    }
                    variant="contained"
                  >
                    {showForms && 'Back to Company List'}
                  </Button>
                ) : null}
                {showAddButton ? (
                  <>
                    <Button
                      onClick={() => {
                        setShowForms(true);
                        setEditFrom(false);
                      }}
                      startIcon={
                        <SvgIcon fontSize="small">
                          <AddIcon />
                        </SvgIcon>
                      }
                      variant="contained"
                    >
                      Add
                    </Button>
                  </>
                ) : null}
              </div>
            </Stack>
            {showForms ? (
              <CompaniesAddForm />
            ) : (
              <>
                {!editFrom ? (
                  <>
                    <Grid container spacing={3}>
                      {companyList.map((company, index) => (
                        <Grid xs={12} md={6} lg={4} key={index.toString()}>
                          <CompanyCard
                            key={index.toString()}
                            company={company}
                            setEditFrom={setEditFrom}
                            setEditCompanyData={setEditCompanyData}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </>
                ) : (
                  <>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      sx={{
                        position: 'absolute',
                        top: '30px',
                        left: { md: '20px', lg: '300px' }
                      }}
                    >
                      <Button
                        onClick={() => {
                          setShowForms(showForms);
                          setEditFrom(false);
                        }}
                        startIcon={
                          <SvgIcon fontSize="small">
                            <ArrowBackIosNewIcon />
                          </SvgIcon>
                        }
                        variant="text"
                      >
                        Back
                      </Button>
                    </Stack>
                    <CompanyEdit
                      company={editCompanyData}
                      setEditFrom={setEditFrom}
                    />
                  </>
                )}
              </>
            )}
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export default Company;
