import { useEffect, useState } from 'react';
import React from 'react';

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Unstable_Grid2 as Grid,
  Stack,
  IconButton,
  Typography
} from '@mui/material';
import { toast } from 'react-hot-toast';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { arrayUnion, doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { useDispatch, useSelector } from 'react-redux';
import { hideLoader, showLoader } from '../../Store/Sclies/loaderSlice';
import CSVReader from 'react-csv-reader';
import { useParams } from 'react-router-dom';
import './multipleCode.css';
import Selects from 'react-select';

const MultipleCodeForm = () => {
  const dispatch = useDispatch();
  const [timeMilisecond, setTimeMilisecond] = useState(0);
  const [codeFile, setCodeFile] = useState(null);
  const [previewUrlFile, setPreviewUrlFile] = useState(null);
  const isLoading = useSelector((state) => state.loader);
  const { id, couponData } = useParams();
  const [error, setError] = useState(false);
  const [eror, setEror] = useState(false);

  const Add_CuratorFrom_Firebase = async () => {
    if (previewUrlFile) {
      setError(false);
      dispatch(showLoader());
      try {
        const documentRef = doc(
          db,
          `Admin/AdminPanelSubCol/Company/${id}/Coupon/${couponData}`
        );
        const documentSnapshot = await getDoc(documentRef);
        const existingData = documentSnapshot.data();

        const existingCoupenCodeFile = existingData?.CouponCodeFile || [];

        const flattenedCodeFile = codeFile.reduce((arr, item) => {
          return arr.concat(item);
        }, []);

        const updatedCoupenCodeFile = arrayUnion(
          ...existingCoupenCodeFile,
          ...flattenedCodeFile
        );
        if (previewUrlFile) {
          setError(false);
          await updateDoc(documentRef, {
            CouponCodeFile: updatedCoupenCodeFile
          });
        }

        toast.success('Add Multiple Code Successfully');
        setCodeFile(null);
        setPreviewUrlFile(null);
        setTimeMilisecond(null);
        dispatch(hideLoader());
      } catch (error) {
        dispatch(hideLoader());
        toast.error('Code not added!');
        console.error('Error updating array in Firestore:', error);
      }
    } else {
      setError(true);
    }
  };
  const handleSubmit = (event) => {
    Add_CuratorFrom_Firebase();
    event.preventDefault();
  };
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setError(false);
      setPreviewUrlFile(file.name);

      const reader = new FileReader();
      reader.readAsDataURL(file);
    }
  };
  const papaparseOptions = {
    header: false,
    dynamicTyping: true,
    skipEmptyLines: true
  };

  const handleForce = (data) => {
    const transformedData =
      data &&
      data.map((item, index) => {
        let timeUpdate = timeMilisecond;
        const date = new Date().valueOf();
        if (index === -1) {
          return { data1: item[0] };
        } else {
          return {
            code: item[0],
            userId: '',
            creation_Date: date,
            expire: timeUpdate,
            expire_Time: ''
          };
        }
      });
    setCodeFile(transformedData);
  };
  useEffect(() => {
    handleForce(codeFile);
  }, [timeMilisecond]);

  const hourOptions = [...Array(72).keys()].map((hour) => ({
    value: `${hour}`.padStart(2, '0'),
    label: `${hour}`.padStart(2, '0')
  }));
  const minuteOptions = [...Array(60).keys()].map((minute) => ({
    value: `${minute}`.padStart(2, '0'),
    label: `${minute}`.padStart(2, '0')
  }));
  const [selectedHour, setSelectedHour] = useState(null);
  const [selectedMinute, setSelectedMinute] = useState(null);

  const handleHourChange = (selectedOption) => {
    setSelectedHour(selectedOption);
  };

  const handleMinuteChange = (selectedOption) => {
    setSelectedMinute(selectedOption);
  };

  const millisecondsInHour = 60 * 60 * 1000;
  const millisecondsInMinutes = 60 * 1000;

  useEffect(() => {
    if (selectedHour && selectedMinute) {
      const hourMiliseconds = selectedHour.value * millisecondsInHour;
      const minuteMiliseconds = selectedMinute.value * millisecondsInMinutes;
      const totalMiliseconds = hourMiliseconds + minuteMiliseconds;
      setTimeMilisecond(totalMiliseconds);
      setEror(false);
    }
  }, [selectedHour, selectedMinute]);

  const customHourFilter = (option, inputValue) => {
    const numericInputValue = Number(inputValue);
    return (
      option.label.startsWith(inputValue) &&
      numericInputValue >= 0 &&
      numericInputValue < 72
    );
  };

  const customMinuteFilter = (option, inputValue) => {
    const numericInputValue = Number(inputValue);
    return (
      option.label.startsWith(inputValue) &&
      numericInputValue >= 0 &&
      numericInputValue < 60
    );
  };
  const handleHourKeyDown = (e) => {
    if (!/^\d$/.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <CardContent sx={{ p: 6 }}>
          <Box sx={{ m: -1.5 }}>
            <Grid container spacing={3}>
              <Grid xs={12} md={6}>
                <div className="time-picker-container">
                  <div className="time-picker-dropdown">
                    <label>Hour:</label>
                    <Selects
                      value={selectedHour}
                      onChange={handleHourChange}
                      options={hourOptions}
                      placeholder="HH"
                      isSearchable={true}
                      isClearable={true}
                      onKeyDown={handleHourKeyDown}
                      filterOption={customHourFilter}
                      className="time-picker-time hour-dropdown"
                      classNamePrefix="time-picker-time"
                      menuPlacement="bottom"
                      menuShouldScrollIntoView={false}
                      components={{
                        MenuList: ({ children, maxHeight }) => (
                          <div style={{ maxHeight }}>{children}</div>
                        )
                      }}
                    />
                  </div>
                  <div className="time-picker-dropdown">
                    <label>Minute:</label>
                    <Selects
                      value={selectedMinute}
                      onChange={handleMinuteChange}
                      options={minuteOptions}
                      placeholder="MM"
                      isSearchable={true}
                      isClearable={true}
                      onKeyDown={handleHourKeyDown}
                      filterOption={customMinuteFilter}
                      classNamePrefix="time-picker-time"
                      menuPlacement="bottom"
                      menuShouldScrollIntoView={false}
                      components={{
                        MenuList: ({ children, maxHeight }) => (
                          <div style={{ maxHeight }}>{children}</div>
                        )
                      }}
                    />
                  </div>
                </div>
                {eror && (
                  <Typography style={{ color: 'red' }}>
                    Please select an Time.
                  </Typography>
                )}
              </Grid>
              <Grid xs={12} md={6}></Grid>
              <Grid xs={12} md={6}>
                <Stack
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-evenly"
                >
                  <Stack sx={{ alignItems: 'center', width: '150px' }}>
                    <label
                      onChange={handleFileUpload}
                      htmlFor="raised-button-file2"
                      accessKey=".csv"
                    >
                      <CSVReader
                        cssClass="react-csv-input d-none"
                        label="Select CSV with secret Death Star statistics"
                        onFileLoaded={handleForce}
                        parserOptions={papaparseOptions}
                        inputId="raised-button-file2"
                      />
                    </label>
                    <IconButton sx={{ width: '50px', height: '50px' }}>
                      <label htmlFor="raised-button-file2">
                        <CloudUploadIcon color="primary" />
                      </label>
                    </IconButton>
                    <Typography>Upload Csv File</Typography>
                    {error && (
                      <Typography style={{ color: 'red' }}>
                        Please select an File.
                      </Typography>
                    )}
                  </Stack>
                </Stack>
              </Grid>
              <Grid xs={12} md={6}>
                <Stack
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-evenly"
                >
                  {previewUrlFile && (
                    <Card
                      sx={{
                        alignItems: 'center'
                      }}
                    >
                      <CardContent>
                        <p>{previewUrlFile}</p>
                      </CardContent>
                    </Card>
                  )}
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button variant="contained" type="submit" disabled={isLoading}>
            Add Code
          </Button>
        </CardActions>
      </Card>
    </form>
  );
};
export default MultipleCodeForm;
