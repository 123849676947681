import React, { useEffect } from 'react';
import { Box, Card, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useState } from 'react';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import { useDispatch, useSelector } from 'react-redux';
import { db } from '../../firebase';
import { hideLoader, showLoader } from '../../Store/Sclies/loaderSlice';
import { useParams } from 'react-router-dom';
import moment from 'moment/moment';
import { v4 as uuidv4 } from 'uuid';
const CompanyCoupenCode = (props) => {
  const dispatch = useDispatch();
  // const { id } = useParams();
  const { couponData } = useParams();
  const [row, setRow] = useState([]);
  const [pageSize, setPageSize] = useState(7);
  const isLoading = useSelector((state) => state.loader);
  const [CouponCodeTYpe, setCouponCodeTYpe] = useState('');
  const [totalUser, setTotaluser] = useState(0);
  const [totalCode, setTotalCode] = useState(0);
  const [usedCode, setUsedCode] = useState(0);
  const [createCouponDate, setCreateCouponDate] = useState('');
  const [code, setCode] = useState('');

  let matchedCouponData = null;

  props.sendCouponCodeType(CouponCodeTYpe);
  props.sendTotalUser(totalUser);
  props.sendTotalCode(totalCode);
  props.sendUsedCode(usedCode);
  useEffect(() => {
    dispatch(showLoader());

    const fetchData = async () => {
      try {
        // const companyRef = collection(
        //   db,
        //   `/Admin/AdminPanelSubCol/Company/${id}/Coupon`
        // );
        const companyRef = doc(db, `Coupon/${couponData}`);
        const querySnapshot = await getDoc(companyRef);
        const transformedRows = [];
        const dataTable = [];
        const newUserList = [];

        if (querySnapshot.exists()) {
          const docData = querySnapshot.data();
          console.log('docData', docData);
          for (const key in docData) {
            if (Object.hasOwnProperty.call(docData, key)) {
              transformedRows.push({ docData, id: docData.FirebaseId });
              dataTable.push(docData);
            }
          }

          transformedRows.map(async (row) => {
            dataTable.push(row);
          });
          console.log('Document data:', docData);
        } else {
          console.log('Document does not exist');
        }
        console.log('datetable', dataTable);
        for (const coupon of dataTable) {
          if (coupon.FirebaseId === couponData) {
            matchedCouponData = {
              CouponType: coupon.CouponType,
              Data:
                coupon.CouponType === 'Single Code'
                  ? coupon.SingleCode
                  : coupon.CouponType === 'Link'
                  ? coupon.Link
                  : coupon.CouponType === 'Multiple Code'
                  ? coupon.CouponCodeFile
                  : null
            };
            break;
          }
        }
        if (matchedCouponData) {
          const userCouponRef = collection(db, 'Users');
          const CouponTYpe = matchedCouponData.CouponType;
          if (CouponTYpe !== 'Multiple Code') {
            setCreateCouponDate(matchedCouponData.Data.creationDate);
          }

          let userIds = [];
          let GetIdList = [];
          setCouponCodeTYpe(CouponTYpe);
          if (CouponTYpe === 'Multiple Code') {
            userIds = matchedCouponData.Data.map((res) => res);
            const nonEmptyUserIds = matchedCouponData.Data.map((res) => res);
            GetIdList.push(...nonEmptyUserIds);
          } else if (CouponTYpe === 'Link') {
            userIds = matchedCouponData.Data.UserId.map((res) => res);
            const nonEmptyUserIds = matchedCouponData.Data.UserId.map(
              (res) => res
            );
            GetIdList.push(...nonEmptyUserIds);
          } else if (CouponTYpe === 'Single Code') {
            userIds = matchedCouponData.Data.UserId.map((res) => res.Id);
            const nonEmptyUserIds = matchedCouponData.Data;
            GetIdList.push(nonEmptyUserIds);
          }

          setTotalCode(GetIdList.length);
          const dataUser = [];
          const userQuerySnapshot = await getDocs(userCouponRef);
          userQuerySnapshot.forEach((doc) => {
            dataUser.push({ uid: doc.id, ...doc.data() });
          });

          const filteredUsers = dataUser.filter((user) => {
            if (CouponTYpe === 'Multiple Code') {
              return userIds.some((res) => res);
            } else if (CouponTYpe === 'Link') {
              return userIds.some((res) => res);
            } else {
              return userIds.includes(user.uid);
            }
          });

          const newUserListWithId = [];
          filteredUsers.forEach((user, index) => {
            newUserListWithId.push({
              CouponTYpe,
              ...user,
              id: CouponTYpe === 'Single Code' ? index + 1 : undefined
            });
          });

          let matchWithOutId = [];

          const updatedUserList = (newUserListWithId, matchedData) => {
            setCode(matchedData[0].code);
            const matchingData = newUserListWithId.filter((item) => {
              if (CouponTYpe === 'Multiple Code') {
                matchedData.some((dataItem) => dataItem.userId === item.uid);
                matchWithOutId = GetIdList.filter((res) => res.userId === '');
                return { ...matchedData };
              } else if (CouponTYpe === 'Single Code') {
                matchedData.some((dataItem) => dataItem.UserId.Id === item.uid);
                return { ...matchedData };
              } else if (CouponTYpe === 'Link') {
                matchedData.some((dataItem) => dataItem.Id === item.uid);
                return { ...matchedData };
              }
            });

            const mergedData = matchingData.map((item) => {
              if (CouponTYpe === 'Multiple Code') {
                let matchedData1 = [];
                let newData = [];
                matchedData.forEach((dataItem) => {
                  if (dataItem.userId === item.uid) {
                    matchedData1.push(dataItem);
                  }
                });

                matchedData1.forEach((dataItem) => {
                  newData.push({ ...item, ...dataItem });
                });
                return newData;
              } else if (CouponTYpe === 'Link') {
                let matchedData1 = [];
                let newData = [];
                matchedData.forEach((dataItem) => {
                  if (dataItem.Id === item.uid) {
                    matchedData1.push(dataItem);
                  }
                });

                matchedData1.forEach((dataItem) => {
                  newData.push({ ...item, ...dataItem });
                });
                return newData;
                // const matchedData1 = matchedData.find(
                //   (dataItem) => dataItem.Id === item.uid
                // );
                // return { ...item, ...matchedData1 };
              } else if (CouponTYpe === 'Single Code') {
                const matchedDatas = matchedData[0].UserId;
                const matchedData1 = matchedDatas.find(
                  (dataItem) => dataItem.Id === item.uid
                );
                return { ...item, ...matchedData1 };
              }
            });

            const finalData = [];
            mergedData.forEach((arr) => {
              if (arr.length > 0) {
                finalData.push(arr);
              }
            });
            if (CouponTYpe !== 'Single Code') {
              return finalData.flat();
            } else {
              return mergedData;
            }
          };

          const mergedData = updatedUserList(newUserListWithId, GetIdList);

          let MatchList = [];
          mergedData.filter((res, index) => {
            if (res.userId) {
              MatchList.push({
                ...res,
                id: index + 1
              });
            }
            if (res.Id) {
              MatchList.push({
                ...res,
                id: index + 1
              });
            }
          });

          if (CouponTYpe !== 'Single Code') {
            setTotaluser(MatchList.length);
          } else {
            setTotaluser(mergedData.length);
          }

          const matchWithOutIdWithDefaultUserId = matchWithOutId.map((item) => {
            return {
              ...item,
              id: uuidv4()
            };
          });
          setUsedCode(matchWithOutIdWithDefaultUserId.length);
          if (matchWithOutId && mergedData && CouponTYpe === 'Multiple Code') {
            const newUserLists = [
              ...MatchList,
              ...matchWithOutIdWithDefaultUserId
            ];
            newUserList.push(newUserLists);
          } else if (CouponTYpe === 'Link') {
            newUserList.push([...MatchList]);
          } else {
            newUserList.push(mergedData);
          }
        }
        setRow(...newUserList);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      dispatch(hideLoader());
    };
    fetchData();
  }, []);

  matchedCouponData &&
    matchedCouponData.Data.forEach((dataEntry) => {
      dataEntry.expire = dataEntry.expire_Time - new Date().valueOf();
    });

  const columns = [
    CouponCodeTYpe && CouponCodeTYpe === 'Multiple Code'
      ? {
          flex: 0.5,
          minWidth: 120,
          field: 'code',
          headerName: 'Coupon Code',
          renderCell: (params) => {
            const { row } = params;
            return (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography
                    noWrap
                    variant="body2"
                    sx={{ color: 'primary', fontWeight: 600, marginLeft: 2 }}
                  >
                    {row?.code}
                  </Typography>
                </Box>
              </Box>
            );
          }
        }
      : null,
    CouponCodeTYpe && CouponCodeTYpe === 'Single Code'
      ? {
          flex: 0.5,
          minWidth: 120,
          field: 'code',
          headerName: 'Coupon Code',
          renderCell: () => {
            return (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography
                    noWrap
                    variant="body2"
                    sx={{ color: 'primary', fontWeight: 600, marginLeft: 2 }}
                  >
                    {code}
                  </Typography>
                </Box>
              </Box>
            );
          }
        }
      : null,
    {
      flex: 0.5,
      minWidth: 120,
      field: 'name',
      headerName: 'Name',
      renderCell: (params) => {
        const { row } = params;

        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                noWrap
                variant="body2"
                sx={{ color: 'primary', fontWeight: 600, marginLeft: 2 }}
              >
                {row?.name}
              </Typography>
            </Box>
          </Box>
        );
      }
    },
    {
      flex: 0.5,
      minWidth: 200,
      field: 'email',
      headerName: 'Email',
      renderCell: (params) => {
        const { row } = params;

        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                noWrap
                variant="body2"
                sx={{ color: 'primary', fontWeight: 600, marginLeft: 2 }}
              >
                {row?.email}
              </Typography>
            </Box>
          </Box>
        );
      }
    },

    CouponCodeTYpe && CouponCodeTYpe !== 'Multiple Code'
      ? {
          flex: 0.5,
          minWidth: 160,
          field: 'datex',
          headerName: 'Code Used Date',
          renderCell: (params) => {
            const { row } = params;
            const CreationDate = row?.Date;
            const Formatdate = moment(CreationDate).format('DD-MM-YYYY HH:mm');
            return (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography
                    noWrap
                    variant="body2"
                    sx={{ color: 'primary', fontWeight: 600, marginLeft: 2 }}
                  >
                    {Formatdate}
                  </Typography>
                </Box>
              </Box>
            );
          }
        }
      : null,

    CouponCodeTYpe && CouponCodeTYpe === 'Multiple Code'
      ? {
          flex: 0.5,
          minWidth: 160,
          field: 'creation_Date',
          headerName: 'Creation Date',
          renderCell: (params) => {
            const { row } = params;
            const CreationDate = row?.creation_Date;
            const Formatdate = moment(CreationDate).format('DD-MM-YYYY HH:mm');
            return (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography
                    noWrap
                    variant="body2"
                    sx={{ color: 'primary', fontWeight: 600, marginLeft: 2 }}
                  >
                    {Formatdate}
                  </Typography>
                </Box>
              </Box>
            );
          }
        }
      : null,
    CouponCodeTYpe && CouponCodeTYpe !== 'Multiple Code'
      ? {
          flex: 0.5,
          minWidth: 160,
          field: 'date',
          headerName: 'Creation Date',
          renderCell: () => {
            const Formatdate =
              moment(createCouponDate).format('DD-MM-YYYY HH:mm');
            return (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography
                    noWrap
                    variant="body2"
                    sx={{ color: 'primary', fontWeight: 600, marginLeft: 2 }}
                  >
                    {Formatdate}
                  </Typography>
                </Box>
              </Box>
            );
          }
        }
      : null,
    CouponCodeTYpe && CouponCodeTYpe === 'Multiple Code'
      ? {
          flex: 0.5,
          minWidth: 200,
          field: 'expire',
          headerName: 'Validity',
          renderCell: (params) => {
            const { row } = params;
            const validate = row?.expire;
            const totalMinutes = Math.floor(validate / (1000 * 60));
            const hours = Math.floor(totalMinutes / 60);
            const minutes = totalMinutes % 60;

            return (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography
                    noWrap
                    variant="body2"
                    sx={{ color: 'primary', fontWeight: 600, marginLeft: 2 }}
                  >
                    {`${hours} hour${
                      hours > 1 ? 's' : ''
                    } and ${minutes} minute${minutes > 1 ? 's' : ''}`}
                  </Typography>
                </Box>
              </Box>
            );
          }
        }
      : null,
    CouponCodeTYpe && CouponCodeTYpe === 'Multiple Code'
      ? {
          flex: 0.5,
          minWidth: 160,
          field: 'CodeUsedDate',
          headerName: 'Code Used Date',
          renderCell: (params) => {
            const { row } = params;
            const expireDate = row?.CodeUsedDate;
            const Formatdate = expireDate
              ? moment(expireDate).format('DD:MM:YYYY HH:mm')
              : '';
            return (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography
                    noWrap
                    variant="body2"
                    sx={{ color: 'primary', fontWeight: 600, marginLeft: 2 }}
                  >
                    {Formatdate}
                  </Typography>
                </Box>
              </Box>
            );
          }
        }
      : null,
    CouponCodeTYpe && CouponCodeTYpe === 'Multiple Code'
      ? {
          flex: 0.5,
          minWidth: 160,
          field: 'expire_Time',
          headerName: 'Expire Date',
          renderCell: (params) => {
            const { row } = params;
            const expireDate = row?.expire_Time;
            const Formatdate = expireDate
              ? moment(expireDate).format('DD:MM:YYYY HH:mm')
              : '';
            return (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography
                    noWrap
                    variant="body2"
                    sx={{ color: 'primary', fontWeight: 600, marginLeft: 2 }}
                  >
                    {Formatdate}
                  </Typography>
                </Box>
              </Box>
            );
          }
        }
      : null
  ].filter((column) => column !== null);
  const sorted = [...row];
  console.log(sorted.sort((a, b) => b.Date - a.Date));
  return (
    <>
      <Card>
        <DataGrid
          loading={isLoading}
          rows={sorted}
          columns={columns}
          pageSize={pageSize}
          disableVirtualization={true}
          rowsPerPageOptions={[7, 10, 25, 50]}
          disableRowSelectionOnClick
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          sx={{
            height: '500px',
            borderRadius: 2,
            borderTop: 1,
            boxShadow: 2,
            border: 2,
            borderColor: 'primary.light',
            '& .MuiDataGrid-cell:hover': {
              color: 'primary.main'
            },
            '& .MuiDataGrid-cell': {},
            '& .MuiDataGrid-row': {
              borderBottom: '1px solid Lightgray'
            },
            '& .MuiDataGrid-columnHeaders': {
              borderBottom: '1px solid Lightgray',
              bgcolor: 'lightgray',
              paddingLeft: 2
            }
          }}
        />
      </Card>
    </>
  );
};

export default CompanyCoupenCode;
