import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  Stack,
  SvgIcon,
  Typography
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import CuratorForm from '../sections/AddCurator/CuratorForm';
import ListOutCurator from '../sections/AddCurator/ListOutCurator';

const AddCurator = () => {
  const [showForms, setShowForms] = useState(false);
  // const fetchUserType = async () => {
  //   try {
  //     const dataRef = collection(db, 'AdminPanelUser');
  //     const loginUser = JSON.parse(localStorage.getItem('User'));
  //     const q = query(dataRef, where('email', '==', loginUser.email));
  //     const querySnapshot = await getDocs(q);
  //     const data = querySnapshot.docs.map((doc) => doc.data());
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // };
  // useEffect(() => {
  //   fetchUserType();
  // }, []);

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8
        }}
      >
        <Container maxWidth="lg">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Typography variant="h4">Curator</Typography>
              </Stack>
              <div>
                <Button
                  onClick={() => setShowForms(!showForms)}
                  startIcon={
                    <SvgIcon fontSize="small">
                      <AddIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                >
                  {showForms ? 'Show Curator List' : 'Add'}
                </Button>
              </div>
            </Stack>
            {showForms ? (
              <CuratorForm />
            ) : (
              <ListOutCurator sx={{ height: '100%' }} />
            )}
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export default AddCurator;
