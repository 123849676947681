import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloseIcon from '@mui/icons-material/Close';
import DiscountIcon from '@mui/icons-material/Discount';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore
} from 'firebase/firestore';
import { storage } from '../../firebase';
import { deleteObject, ref } from 'firebase/storage';
import { toast } from 'react-hot-toast';
const CompanyCard = (props) => {
  const { company, setEditFrom, setEditCompanyData } = props;
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const deleteFileFromStorage = async (downloadURL) => {
    const storageRef = ref(storage, downloadURL);
    try {
      await deleteObject(storageRef);
    } catch (error) {
      console.error('Error deleting file: ', error);
    }
  };

  // const deleteSubcollection = async (id) => {
  //   try {
  //     const firestore = getFirestore();
  //     const parentDocRef = doc(
  //       firestore,
  //       // 'Admin',
  //       // 'AdminPanelSubCol',
  //       'Company',
  //       id
  //     );
  //     const ref = collection(db, 'HomepageCoupon');
  //     const ref2 = collection(db, 'Coupon');
  //     const querySnapshots = await getDocs(ref);
  //     const querySnapshotsss = await getDocs(ref2);

  //     const promises = querySnapshots.docs.map(async (doc) => {
  //       return doc.data();
  //     });
  //     const promisesss = querySnapshotsss.docs.map(async (doc) => {
  //       return doc.data();
  //     });
  //     const couponDataArray = await Promise.all(promises);
  //     const couponDataArray2 = await Promise.all(promisesss);
  //     const mergedData = couponDataArray.flat();
  //     const mergedData2 = couponDataArray2.flat();
  //     console.log('mergeData', mergedData);
  //     console.log('mergeData', mergedData2);
  //     // const latestdata = mergedData.filter(
  //     //   (data) => data.Company.company_Name === id
  //     // );
  //     // if (latestdata) {
  //     // await deleteDoc(parentDocRefs);
  //     // }
  //     const subcollectionRef = collection(parentDocRef, id);
  //     const querySnapshot = await getDocs(subcollectionRef);

  //     querySnapshot.forEach(() => {
  //       deleteFileFromStorage(doc.data().CouponBgImage);
  //       deleteDoc(doc.ref);
  //     });
  //     const querySnapshot1 = await getDoc(parentDocRef);
  //     if (querySnapshot1.exists) {
  //       deleteFileFromStorage(querySnapshot1.data().bgImage);
  //       deleteFileFromStorage(querySnapshot1.data().logo);
  //       await deleteDoc(parentDocRef);
  //     }
  //     toast.success('Delete Company Successfully!');
  //   } catch (error) {
  //     console.error('Error deleting subcollection:', error);
  //   }
  // };
  const deleteSubcollection = async (id) => {
    try {
      const firestore = getFirestore();
      const parentDocRef = doc(firestore, 'Company', id);
      const ref = collection(firestore, 'HomepageCoupon');
      const ref2 = collection(firestore, 'Coupon');
      const querySnapshots = await getDocs(ref);
      const querySnapshotsss = await getDocs(ref2);

      // Create an array to store promises for deleting documents
      const deletePromises = [];

      querySnapshots.forEach((docs) => {
        const data = docs.data();
        if (data.company_Name === id) {
          const parentDocRef = doc(firestore, 'HomepageCoupon', data.id);
          deletePromises.push(deleteDoc(parentDocRef));
        }
      });

      querySnapshotsss.forEach((docs) => {
        const data = docs.data();
        if (data.CompanyName === id) {
          const parentDocRef = doc(firestore, 'Coupon', data.FirebaseId);
          deletePromises.push(deleteFileFromStorage(data.CouponBgImage));
          deletePromises.push(deleteDoc(parentDocRef));
        }
      });

      await Promise.all(deletePromises);
      const parentDocSnapshot = await getDoc(parentDocRef);
      if (parentDocSnapshot.exists) {
        const parentData = parentDocSnapshot.data();
        if (parentData.company_Name === id) {
          deleteFileFromStorage(parentData.bgImage);
          deleteFileFromStorage(parentData.logo);
          await deleteDoc(parentDocRef);
        }
      }

      toast.success('Delete Company Successfully!');
    } catch (error) {
      console.error('Error deleting subcollection:', error);
    }
  };

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        ':hover': {
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.28)'
        }
      }}
    >
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            pb: 3,
            position: 'relative'
          }}
        >
          <img
            src={company.logo}
            alt="logo"
            style={{
              objectFit: 'contain',
              maxWidth: '100px',
              maxHeight: '45px'
            }}
          />
          <Stack
            sx={{
              position: 'absolute',
              top: '-100%',
              left: '9  0%',
              bottom: 0,
              right: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            {' '}
            <Stack direction="row">
              <Tooltip title="Company Delete">
                <IconButton
                  onClick={() => setOpen(true)}
                  sx={{
                    color: 'red',
                    ':hover': {
                      bgcolor: '#6366F1',
                      color: 'white'
                    }
                  }}
                >
                  <DeleteForeverIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit">
                <IconButton
                  onClick={() => {
                    setEditFrom(true);
                    setEditCompanyData(company);
                  }}
                  sx={{
                    color: 'green',
                    ':hover': {
                      bgcolor: '#6366F1',
                      color: 'white'
                    }
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>
        </Box>
        <Typography align="center" gutterBottom variant="h5">
          {company.company_Name}
        </Typography>
        <Typography
          align="center"
          variant="body1"
          dangerouslySetInnerHTML={{ __html: company.desc }}
        />
      </CardContent>
      <Box sx={{ flexGrow: 1 }} />
      <Divider />
      <Grid sx={{ display: 'flex' }}>
        <CardActions disableSpacing>
          <Button
            startIcon={<DiscountIcon />}
            onClick={() => navigate(`/company/${company.company_Name}`)}
          >
            Coupons
          </Button>
        </CardActions>
      </Grid>
      <Dialog open={open} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ margin: '10px', marginBottom: '0' }}>
          Delete Company
        </DialogTitle>
        <Box position="absolute" top={10} right={10}>
          <Tooltip title="close">
            <IconButton
              onClick={() => setOpen(false)}
              sx={{
                ':hover': {
                  bgcolor: '#6366F1',
                  color: 'white',
                  marginLeft: 'auto'
                }
              }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <DialogContent>
          <Typography>Do you really want to delete this company?</Typography>
        </DialogContent>
        <DialogActions sx={{ marginBottom: '10px' }}>
          <Button
            color="primary"
            onClick={() => setOpen(false)}
            variant="contained"
          >
            No
          </Button>
          <Button
            color="error"
            onClick={() => {
              setOpen(false);
              deleteSubcollection(company.company_Name);
            }}
            variant="contained"
          >
            {/* {loader ? (
              <CircularProgress size={30} style={{ color: '#fff' }} />
            ) : (
              'Yes'
            )} */}
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

CompanyCard.propTypes = {
  company: PropTypes.object.isRequired
};
export default CompanyCard;
