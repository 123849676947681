import React, { useEffect } from 'react';

import {
  Box,
  Card,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography
} from '@mui/material';

import { DataGrid } from '@mui/x-data-grid';
import { useState } from 'react';
import {
  collection,
  deleteDoc,
  getDocs,
  onSnapshot,
  query,
  where
} from 'firebase/firestore';
import { db } from '../../firebase';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { hideLoader, showLoader } from '../../Store/Sclies/loaderSlice';
const ListOutCurator = () => {
  const dispatch = useDispatch();

  const [row, setRow] = useState([]);
  const [pageSize, setPageSize] = useState(7);
  const isLoading = useSelector((state) => state.loader);

  useEffect(() => {
    dispatch(showLoader()); //

    const ref = collection(db, 'AdminPanelUser');
    const q = query(ref, where('role', '==', 'curator'));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      let i = 0;
      const documents = snapshot.docs.map((doc) => ({
        id: doc.id,
        ids: i++,
        ...doc.data()
      }));

      setRow(documents);
      dispatch(hideLoader());
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const columns = [
    {
      flex: 0.05,
      field: 'id',
      headerName: 'id',
      renderCell: (params) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                noWrap
                variant="body2"
                sx={{ color: 'primary', fontWeight: 600, marginLeft: 2 }}
              >
                {params.row.ids + 1}
              </Typography>
            </Box>
          </Box>
        );
      }
    },
    {
      flex: 0.5,
      minWidth: 290,
      field: 'name',
      headerName: 'Name',
      renderCell: (params) => {
        const { row } = params;

        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                noWrap
                variant="body2"
                sx={{ color: 'primary', fontWeight: 600, marginLeft: 2 }}
              >
                {row?.email}
              </Typography>
            </Box>
          </Box>
        );
      }
    },
    {
      flex: 0.4,
      minWidth: 290,
      field: 'Password',
      headerName: 'Password',
      renderCell: (params) => {
        const { row } = params;
        const [showPassword, setShowPassword] = React.useState(false);
        const handleClickShowPassword = () => setShowPassword((show) => !show);
        const handleMouseDownPassword = (event) => {
          event.preventDefault();
        };
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <OutlinedInput
                value={row?.password}
                disabled
                sx={{ outline: 'none', height: '40px' }}
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Box>
          </Box>
        );
      }
    },
    {
      flex: 0.1,
      minWidth: 20,
      field: 'delett',
      headerName: 'Delete',
      renderCell: (params) => {
        const { row } = params;
        const deleteDocuments = async () => {
          try {
            const collectionRef = collection(db, 'AdminPanelUser');

            const q = query(collectionRef, where('email', '==', row.email));

            const querySnapshot = await getDocs(q);

            querySnapshot.forEach(async (doc) => {
              await deleteDoc(doc.ref);
            });
          } catch (error) {
            console.error('Error deleting documents: ', error);
          }
        };

        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <IconButton
                aria-label="delete"
                onClick={deleteDocuments}
                sx={{
                  ':hover': {
                    bgcolor: 'red',
                    color: 'white'
                  }
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          </Box>
        );
      }
    }
  ];

  return (
    <Card>
      {/* <CardContent> */}
      <DataGrid
        loading={isLoading}
        rows={row}
        columns={columns}
        pageSize={pageSize}
        rowsPerPageOptions={[7, 10, 25, 50]}
        disableRowSelectionOnClick
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        sx={{
          height: '500px',
          borderRadius: 2,
          borderTop: 1,
          boxShadow: 2,
          border: 2,
          borderColor: 'primary.light',
          '& .MuiDataGrid-cell:hover': {
            color: 'primary.main'
          },
          '& .MuiDataGrid-cell': {},
          '& .MuiDataGrid-row': {
            borderBottom: '1px solid Lightgray'
          },
          '& .MuiDataGrid-columnHeaders': {
            borderBottom: '1px solid Lightgray',
            bgcolor: 'lightgray',
            paddingLeft: 2
          }
        }}
      />
      {/* </CardContent> */}
    </Card>
  );
};

export default ListOutCurator;
