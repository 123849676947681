import React from 'react';

import { useCallback, useState } from 'react';

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Unstable_Grid2 as Grid,
  IconButton,
  Typography
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Stack } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { db, storage } from '../../firebase';
import { doc, setDoc } from 'firebase/firestore';
import { toast } from 'react-hot-toast';
import { hideLoader, showLoader } from '../../Store/Sclies/loaderSlice';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const CompaniesAddForm = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.loader);

  const [values, setValues] = useState({
    companyName: '',
    desc: '',
    urlLink: '',
    title: '',
    metaTitle: '',
    metaDescription: '',
    howItWorksTitle: '',
    howItWorks: '',
    generalInfoTitle: '',
    generalInfoDescription: '',
    altBackgroundImage: '',
    altHowItWorks: '',
    altGeneralImage: '',
    faq: [
      { question: '', answer: '' },
      { question: '', answer: '' },
      { question: '', answer: '' },
      { question: '', answer: '' },
      { question: '', answer: '' }
    ]
  });
  const [previewUrlbg, setPreviewUrlbg] = useState(null);
  const [previewUrlLogo, setPreviewUrlLogo] = useState(null);
  const [previewUrlHowItWorks, setPreviewHowItWorks] = useState(null);
  const [previewGeneralInfo, setPreviewGeneralInfo] = useState('');
  const [selectedImage, setSelectedImage] = useState([]);
  const [selectedImageLogo, setSelectedImageLogo] = useState([]);
  const [selectedImageHowItWorks, setSelectedImageHowItWorks] = useState('');
  const [selectedImageGeneralInfo, setSelectedImageGeneralInfo] = useState('');

  const [error1, setError1] = useState(false);
  const [error2, setError2] = useState(false);

  const handleChange = useCallback((event) => {
    setValues((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value
    }));
  }, []);

  const handleDescEdit = (value) => {
    setValues((prevState) => ({
      ...prevState,
      desc: value
    }));
  };

  const handleHowItWorks = (value) => {
    setValues((prevState) => ({
      ...prevState,
      howItWorks: value
    }));
  };
  const handleGeneralInfo = (value) => {
    setValues((prevState) => ({
      ...prevState,
      generalInfoDescription: value
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (
      selectedImage &&
      selectedImageLogo &&
      selectedImageHowItWorks &&
      selectedImageGeneralInfo
    ) {
      dispatch(showLoader());
      try {
        // Upload image to Firestore
        // Refs
        const storageRef = ref(storage, `CompanyImg/${selectedImage.name}`);
        const storageRefforBg = ref(
          storage,
          `CompanyBgImg/${selectedImageLogo.name}`
        );
        const storageRefHowItWOrks = ref(
          storage,
          `CompanyImg/${values.companyName}_${selectedImageHowItWorks.name}`
        );
        const storageRefGeneralInfo = ref(
          storage,
          `CompanyImg/${values.companyName}_${selectedImageGeneralInfo.name}`
        );

        // Upload bytes
        await uploadBytes(storageRef, selectedImageLogo);
        await uploadBytes(storageRefforBg, selectedImage);
        await uploadBytes(storageRefHowItWOrks, selectedImageHowItWorks);
        await uploadBytes(storageRefGeneralInfo, selectedImageGeneralInfo);

        // Get url
        const downloadURL = await getDownloadURL(storageRefforBg);
        const downloadURLLogo = await getDownloadURL(storageRef);
        const downloadURL3 = await getDownloadURL(storageRefHowItWOrks);
        const downloadURLGeneralInfo = await getDownloadURL(
          storageRefGeneralInfo
        );

        const documentRef = doc(db, 'Company', values.companyName); // Specify the document ID
        await setDoc(documentRef, {
          company_Name: values.companyName,
          desc: values.desc,
          urlLink: values.urlLink,
          title: values.title,
          metaTitle: values.metaTitle,
          metaDescription: values.metaDescription,
          logo: downloadURLLogo,
          bgImage: downloadURL,
          altBackgroundImage: values.altBackgroundImage,
          timestamp: new Date(),
          howItWorksImage:
            downloadURL3 !== '' ? downloadURL3 : previewUrlHowItWorks,
          howItWorks: values.howItWorks,
          howItWorksTitle: values.howItWorksTitle,
          altHowItWorks: values.altHowItWorks,
          generalInfoTitle: values.generalInfoTitle,
          generalInfoDescription: values.generalInfoDescription,
          generalInfoImage:
            downloadURLGeneralInfo !== ''
              ? downloadURLGeneralInfo
              : previewGeneralInfo,
          altGeneralImage: values.altGeneralImage,
          faq: values.faq,
          status: 'active'
        });

        setValues({
          companyName: '',
          desc: '',
          urlLink: '',
          title: '',
          metaTitle: '',
          howItWorks: '',
          generalInfoTitle: '',
          generalInfoDescription: '',
          altBackgroundImage: '',
          altHowItWorks: '',
          altGeneralImage: '',
          faq: [
            { question: '', answer: '' },
            { question: '', answer: '' },
            { question: '', answer: '' },
            { question: '', answer: '' },
            { question: '', answer: '' }
          ]
        });
        setSelectedImage('');
        setSelectedImageLogo('');
        setSelectedImageHowItWorks('');
        setSelectedImageGeneralInfo('');
        setPreviewUrlbg(null);
        setPreviewUrlLogo(null);
        setPreviewHowItWorks(null);
        setPreviewGeneralInfo(null);

        dispatch(hideLoader());
        toast.success('Company added Successfully');
      } catch (error) {
        dispatch(hideLoader());
        console.error('Error uploading image:', error);
      }
    } else if (!selectedImageLogo) {
      setError2(true);
    } else {
      setError1(true);
    }
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setError2(false);
      setSelectedImage(file);
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewUrlbg(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleImageUploadLogo = (event) => {
    const file = event.target.files[0];
    if (file) {
      setError1(false);
      setSelectedImageLogo(file);

      const reader = new FileReader();
      reader.onload = () => {
        setPreviewUrlLogo(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageUploadHowItWorks = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImageHowItWorks(file);

      const reader = new FileReader();
      reader.onload = () => {
        setPreviewHowItWorks(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageUploadGeneralInfo = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImageGeneralInfo(file);

      const reader = new FileReader();
      reader.onload = () => {
        setPreviewGeneralInfo(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFaqChange = (event) => {
    const index = event.target.name.split('')[1];

    setValues((prevState) => {
      const updatedFaq = [...prevState.faq];
      updatedFaq[index - 1].question = event.target.value;
      return {
        ...prevState,
        faq: updatedFaq
      };
    });
  };

  const handleAnswerChange = (value, index) => {
    setValues((prevState) => {
      const updatedFaq = [...prevState.faq];

      updatedFaq[index].answer = value;
      return {
        ...prevState,
        faq: updatedFaq
      };
    });
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <Card>
        <CardHeader title="Company Profile" />
        <CardContent sx={{ pt: 0 }}>
          <Box sx={{ m: -1.5 }}>
            <Grid container spacing={3}>
              <Grid xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Name of the company"
                  name="companyName"
                  onChange={handleChange}
                  required
                  value={values.companyName}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Link of the company"
                  name="urlLink"
                  onChange={handleChange}
                  required
                  value={values.urlLink}
                />
              </Grid>

              <Grid xs={12} md={6}>
                <CardHeader title="Meta Info" />
                <TextField
                  fullWidth
                  label="Meta title of the company"
                  name="metaTitle"
                  onChange={handleChange}
                  required
                  value={values.metaTitle}
                />
              </Grid>
              <Grid xs={12} md={6} alignItems={'flex-end'} display={'flex'}>
                <TextField
                  fullWidth
                  label="Meta description of the company"
                  name="metaDescription"
                  onChange={handleChange}
                  value={values.metaDescription}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <CardHeader title="Company Description Title" />

                <TextField
                  fullWidth
                  label="Company Description Title"
                  name="title"
                  onChange={handleChange}
                  required
                  value={values.title}
                  style={{ marginBottom: '20px' }}
                />
                <ReactQuill
                  theme="snow" // Choose a theme - snow is one of the themes provided by Quill
                  value={values.desc}
                  onChange={handleDescEdit}
                  style={{ height: '200px', marginBottom: '60px' }}
                />
                <TextField
                  fullWidth
                  label="Alt tag background picture"
                  name="altBackgroundImage"
                  onChange={handleChange}
                  required
                  value={values.altBackgroundImage}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <Stack
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-evenly"
                >
                  <Stack sx={{ alignItems: 'center', width: '150px' }}>
                    <input
                      accept="image/*"
                      id="image-upload"
                      type="file"
                      style={{ display: 'none' }}
                      onChange={handleImageUploadLogo}
                    />
                    <label htmlFor="image-upload">
                      <IconButton
                        sx={{ width: '50px', height: '50px' }}
                        size="large"
                        component="span"
                      >
                        <CloudUploadIcon color="primary" />
                      </IconButton>
                    </label>

                    <Typography>Logo</Typography>
                    {error1 && (
                      <Typography style={{ color: 'red' }}>
                        Please select an image.
                      </Typography>
                    )}
                  </Stack>
                </Stack>
                <Stack
                  mt={4}
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-evenly"
                >
                  {previewUrlLogo && (
                    <Card className="p-[20px]">
                      <img
                        src={previewUrlLogo}
                        alt="Image Preview."
                        className="max-h-[80px] w-full object-contain"
                      />
                    </Card>
                  )}
                </Stack>
                <Stack
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-evenly"
                >
                  <Stack sx={{ alignItems: 'center', width: '150px' }}>
                    <input
                      onChange={handleImageUpload}
                      accept="image/*"
                      style={{ display: 'none' }}
                      id="raised-button"
                      type="file"
                    />
                    <label htmlFor="raised-button">
                      <IconButton
                        sx={{ width: '50px', height: '50px' }}
                        size="large"
                        component="span"
                      >
                        <CloudUploadIcon color="primary" />
                      </IconButton>
                    </label>
                    <Typography>Description image</Typography>
                    {error2 && (
                      <Typography style={{ color: 'red' }}>
                        Please select an image.
                      </Typography>
                    )}
                  </Stack>
                </Stack>
                <Stack
                  mt={4}
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-evenly"
                >
                  {previewUrlbg && (
                    <Card className="w-full max-w-[600px] h-[400px] lg:h-[390px] sm:h-[280px]">
                      <img
                        src={previewUrlbg}
                        alt="Image Preview."
                        className="max-h-[400px] h-full w-full object-cover"
                      />
                    </Card>
                  )}
                </Stack>
              </Grid>

              <Grid xs={12} md={6}>
                <CardHeader title="How it works" />

                <TextField
                  fullWidth
                  label="How it works title"
                  name="howItWorksTitle"
                  onChange={handleChange}
                  required
                  value={values.howItWorksTitle}
                  style={{ marginBottom: '30px' }}
                />
                <ReactQuill
                  theme="snow" // Choose a theme - snow is one of the themes provided by Quill
                  value={values.howItWorks}
                  onChange={handleHowItWorks}
                  style={{ height: '200px', marginBottom: '60px' }}
                />
                <TextField
                  fullWidth
                  label="Alt tag how it works picture"
                  name="altHowItWorks"
                  onChange={handleChange}
                  required
                  value={values.altHowItWorks}
                />
              </Grid>

              <Grid xs={12} md={6}>
                <Stack
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-evenly"
                >
                  <Stack sx={{ alignItems: 'center', width: '150px' }}>
                    <input
                      onChange={handleImageUploadHowItWorks}
                      accept="image/*"
                      style={{ display: 'none' }}
                      id="raised-button-how"
                      // name="fileInput1"
                      type="file"
                    />
                    <label htmlFor="raised-button-how">
                      <IconButton
                        sx={{ width: '50px', height: '50px' }}
                        size="large"
                        component="span"
                      >
                        <CloudUploadIcon color="primary" />
                      </IconButton>
                    </label>
                    <p>How it works image</p>
                  </Stack>
                </Stack>
                <Stack
                  mt={4}
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-evenly"
                >
                  {previewUrlHowItWorks && (
                    <Card className="w-full max-w-[600px] h-[400px] lg:h-[390px] sm:h-[280px]">
                      <img
                        src={previewUrlHowItWorks}
                        alt="Image Preview."
                        className="max-h-[400px] h-full w-full object-cover"
                      />
                    </Card>
                  )}
                </Stack>
              </Grid>

              <Grid xs={12} md={6}>
                <CardHeader title="General Info" />

                <TextField
                  fullWidth
                  label="General Info title"
                  name="generalInfoTitle"
                  onChange={handleChange}
                  required
                  value={values.generalInfoTitle}
                  style={{ marginBottom: '30px' }}
                />
                <ReactQuill
                  theme="snow" // Choose a theme - snow is one of the themes provided by Quill
                  value={values.generalInfoDescription}
                  onChange={handleGeneralInfo}
                  style={{ height: '200px', marginBottom: '60px' }}
                />
                <TextField
                  fullWidth
                  label="Alt tag general info picture"
                  name="altGeneralImage"
                  onChange={handleChange}
                  required
                  value={values.altGeneralImage}
                />
              </Grid>

              <Grid xs={12} md={6}>
                <Stack
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-evenly"
                >
                  <Stack sx={{ alignItems: 'center', width: '150px' }}>
                    <input
                      onChange={handleImageUploadGeneralInfo}
                      accept="image/*"
                      style={{ display: 'none' }}
                      id="raised-button-general"
                      // name="fileInput1"
                      type="file"
                    />
                    <label htmlFor="raised-button-general">
                      <IconButton
                        sx={{ width: '50px', height: '50px' }}
                        size="large"
                        component="span"
                      >
                        <CloudUploadIcon color="primary" />
                      </IconButton>
                    </label>
                    <p>General info image</p>
                  </Stack>
                </Stack>
                <Stack
                  mt={4}
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-evenly"
                >
                  {previewGeneralInfo && (
                    <Card className="w-full max-w-[600px] h-[400px] lg:h-[390px] sm:h-[280px]">
                      <img
                        src={previewGeneralInfo}
                        alt="Image Preview."
                        className="max-h-[400px] h-full w-full object-cover"
                      />
                    </Card>
                  )}
                </Stack>
              </Grid>
              <Grid xs={12} md={12}>
                <CardHeader title="FAQs" />

                {Array.from({ length: 5 }, (_, index) => (
                  <div key={index}>
                    <TextField
                      fullWidth
                      label={`Question ${index + 1}`}
                      name={`q${index + 1}`}
                      onChange={handleFaqChange}
                      value={values.faq[index].question}
                      style={{ marginBottom: '30px', marginTop: '30px' }}
                    />
                    <ReactQuill
                      theme="snow" // Choose a theme - snow is one of the themes provided by Quill
                      value={values.faq[index].answer}
                      onChange={(value) => handleAnswerChange(value, index)}
                      style={{ height: '100px', marginBottom: '60px' }}
                    />
                  </div>
                ))}
              </Grid>
            </Grid>
          </Box>
        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button variant="contained" type="submit" disabled={isLoading}>
            Submit
          </Button>
        </CardActions>
      </Card>
    </form>
  );
};
export default CompaniesAddForm;
