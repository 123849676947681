import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';

// Create an async thunk for fetching data from Firestore
export const fetchCompanyData = createAsyncThunk(
  'user/companyActions',
  async () => {
    const ref = collection(db, 'Company');
    const querySnapshot = await getDocs(ref);

    const data = querySnapshot.docs.map((doc) => {
      const docData = doc.data();
      // Exclude or convert the non-serializable property
      const { ...serializableData } = docData;
      return serializableData;
    });

    return data;
  }
);

// Create the slice for the user data
const CompanySlice = createSlice({
  name: 'company',
  initialState: {
    data: [],
    loading: false,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanyData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCompanyData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchCompanyData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

// Export the async thunk and the user slice reducer
export const { reducer: companyReducer } = CompanySlice;
// export const { companyActions } = CompanySlice.actions;
